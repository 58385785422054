@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 584px;

  .iconContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    .iconBg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background: map-get($bgColors, primaryWhite);

      .icon {
        width: 20px;
        height: 20px;
        color: map-get($fontColors, mainPurple);
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
    color: map-get($dashboardLight, settingsBg);
    font-family: $boldFont;
    font-size: $fontBannerSmall;
    line-height: 35px;
    text-align: center;
  }

  .description {
    color: map-get($fontColors, mainGrey);
    text-align: center;
    font-size: $fontLarge;
  }
}