@import "../../styles/variables";

.container {
  display: flex;
  color: map-get($fontColors, mainPurple);

  .button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .icon {

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .clickableText {
      font-family: $boldFont;
      font-size: $fontMedium;
      letter-spacing: -0.16px;
      text-decoration-line: underline;
    }
  }

  .disabledButton {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
