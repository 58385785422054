.article {
  padding: 0 40px 0 0;

  @media (max-width: $breakSm) {
    padding: 0;
  }

  .header {
    display: flex;
    font-family: $boldFont;
    gap: 8px;
    font-size: 35px;
    line-height: 1;
    color: map-get($dashboardLight, settingsBg);
    text-transform: capitalize;
    margin-bottom: 22px;
    margin-top: 15px;

    @media (max-width: $breakSm) {
      align-items: center;
      justify-content: flex-end;
      margin-top: 0;
    }
  }

  .baner-description-header {
    margin-bottom: 20px;
  }

  .header-back-icon {
    cursor: pointer;
  }
}

.article-text {
  margin-bottom: 20px;
}

.article-text-container {
  padding: 0 85px;

  @media (max-width: $breakSm) {
    padding: 0 15px;
  }
}

.heading-2 {
  font-size: 21px;
  font-family: $boldFont;
}

.heading-3 {
  font-size: $fontTitle;
  font-family: $boldFont;
}

.paragraph {
  font-size: $fontSemiLarge;
  margin-bottom: 10px;
}

.article-card {
  padding: 0 120px 40px;
  overflow: auto;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: $boxShadow;
  border-radius: $borderRadius12;

  @media (max-width: $breakSm) {
    padding: 0;
  }

  .baner-img {
    min-width: 890px;
    min-height: 336px;
    margin-bottom: 50px;
  }

}

.article-header {
  text-align: center;
  padding-top: 39px;
  padding-bottom: 30px
}

.article-image {
  width: 100%;
  margin-bottom: 50px;
  border-radius: $borderRadius12;
}
