.baner-box {
  padding: 8px;
  height: calc(50vh - 50px);
}

.extended-banner {
  display: flex;
  gap: 40px;
  padding: 2px;
  height: calc(50vh - 50px);

    .content-baner-small {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-image: url("../assets/images/png/smallContentBanner.png");
      background-size: cover;
      padding-left: 40px;
      padding-top: 20px;
      padding-bottom: 20px;

      @media (max-width: $breakSm) {
        display: none;
      }

        .content-banner-small-text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        width: 50%;
        color: map-get($fontColors, mainWhite);

        .small-baner-description-header {
          color: map-get($bgColors, primaryWhite);
          font-size: $fontVerySmall;
          font-family: $boldFont;
          letter-spacing: 1.68px;
        }
        .title-about {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .small-baner-description-title {
            font-size: $fontBanner;
            font-family: $boldFont;
            line-height: 34px;
          }

          .small-baner-description-about {
            color: map-get($bgColors, primaryWhite);
            font-size: $fontMedium;
            font-family: $mediumFont;
          }
        }

        .small-baner-description-link {
          background: none;
          border: none;
          font-size: $fontMedium;
          font-family: $mediumFont;
          letter-spacing: 0.16px;
          text-decoration-line: underline;
          text-underline-offset: 4px;
          cursor: pointer;
        }
      }
    }

  .banner-upgrade-limits {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @media (max-width: $breakSm) {
      margin-top: 10px;
    }

    .upgrade-limits-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 12px;

      &.start-trial {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 40px;
        margin-bottom: 28px;
        background: rgb(129,237,231);
        background: linear-gradient(90deg, rgba(129,237,231,1) 0%, rgba(87,179,251,1) 100%);

        @media (max-width: $breakSm) {
          padding-left: 10px;
          padding-top: 5px;
          margin-bottom: 15px;
        }

          .start-trial-text {
          display: flex;
          flex-direction: column;
          gap: 6px;
          justify-content: center;
          color: #004F68;
          font-size: $fontVerySmall;
          font-family: $boldFont;
          letter-spacing: 1.68px;
          width: 100%;

          .better-text {
            font-size: $fontBannerSmall;
            line-height: 28px;
            letter-spacing: -0.24px;

            @media (max-width: $breakSm) {
              font-size: $fontBilling;
            }
          }

          .trial-text {
            cursor: pointer;
            font-size: $fontMedium;
            font-family: $mediumFont;
            letter-spacing: 0.16px;
            text-decoration-line: underline;
            text-underline-offset: 4px;
          }
        }

        .widgets-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
          width: 100%;
          height: 100%;

          .baner-widget {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 10px 8px 9px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background: map-get($bgColors, mainWhite);
            height: 20%;
            min-height: 30px;

            .name-icon-container {
              display: flex;
              align-items: center;
              gap: 7px;

              .icon {
                display: flex;
                align-items: center;
                width: 12px;
                height: 12px;

                svg {
                  path {
                    stroke: #202020;
                  }
                }
              }

              .widget-name {
                color: map-get($fontColors, mainBlack);
                font-size: 8px;
                font-family: $boldFont;
              }

              @media (min-width: 1920px) {
                .icon {
                  width: 16px;
                  height: 16px;
                }

                .widget-name {
                  font-size: $fontSizeRegular;
                }
              }
            }

            @media (min-width: 1920px) {
              .widget-icon {
                width: 50px;
                height: 16px;
              }
            }
          }
        }
      }

      &.upgrade-now {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 6px;
        height: 100%;
        color: map-get($fontColors, mainWhite);
        background-image: url('../../src/assets/images/png/planLimitsBannerBgRed.png');
        background-size: cover;

        .upgrade-now-title {
          font-size: $fontBannerSmall;
          font-family: $boldFont;
          line-height: 28px;
          letter-spacing: -0.24px;
        }

        .upgrade-now-button {
          color: map-get($fontColors, mainWhite);
          font-size: $fontMedium;
          font-family: $mediumFont;
          letter-spacing: 0.16px;
          text-decoration-line: underline;
          text-underline-offset: 4px;
          background: none;
          border: none;
        }
      }
    }
  }
}

.baner-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: $borderRadius12;
  box-shadow: $boxShadow;
  height: 100%;

  &:hover {
    transform: scale(1.01);
  }

  .baner-img {
    height: 100%;
    width: 50%;
    border-radius: 12px 0 0 12px;
    animation-duration: .5s;
    animation-name: slidein;
    overflow: hidden;

    @media (max-width: $breakMd) {
      display: none;
    }

  @keyframes slidein {
      from {
        transform: scale(0.9);
      }

      to {
        transform: scale(1);
      }
    }

    &.small {
      width: 100%;
    }
  }

  .baner-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 50%;
    border-radius: 0 12px 12px 0;
    padding: 0 8%;
    gap: 8px;
    background: map-get($bgColors, mainWhite);
    position: relative;

    @media (max-width: $breakMd) {
      width: 100%;
      border-radius: $borderRadius12;
      margin-top: 5px;
    }
  }

  .baner-description-about {
    font-family: $regularFont;
    font-size: $fontMedium;
    line-height: 24px;
    color: map-get($fontColors, mainGrey);
  }

  .baner-description-link {
    font-family: $regularFont;
    font-size: $fontMedium;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: map-get($fontColors, mainPurple);
  }
}

.baner-description-header {
  text-transform: uppercase;
  font-family: $boldFont;
  font-size: $fontVerySmall;
  line-height: 18px;
  color: map-get($fontColors, mainBlack);
  letter-spacing: 0.14em;
}

.baner-description-title {
  font-family: $xBoldFont;
  font-size: $fontBanner;
  line-height: 36px;
  color: map-get($fontColors, mainBlack);
}