@import '../../styles/variables';

.outerContainer {
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;

    .heading {
      font-size: 40px;
      font-family: $boldFont;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .formRow {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

input,select {
  height: 40px;
  border-radius: 5px;
}

.label {
  font-family: $boldFont;
}

.divider {
  width: 100%;
  height: 0;
  border-bottom: 1px solid map-get($fontColors, mainGrey);
}

.payoutsTable {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .tableHeader {
    font-family: $boldFont;
  }

  .payoutsTableRow {
    display: grid;
    grid-template-columns: 60px 200px 300px repeat(3, 100px) 150px calc(100% - 60px - 200px - 300px - 300px - 150px);
  }
}

.delete {
  color: map-get($fontColors, errorColor);
  cursor: pointer;

  &:hover {
    transform: translateX(2px);
  }
}
