.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.load {
  position: absolute;
  inset: 0;
  background: map-get($bgColors, mainWhite);
  border-radius: 34px;
  border: 3px solid map-get($fontColors, mainGrey);
  cursor: unset;

  &:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    border-radius: 50%;
    animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    background: map-get($fontColors, mainGrey);
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: map-get($bgColors, mainWhite);
  transition: 0.3s;
  border-radius: 34px;
  border: 3px solid map-get($fontColors, mainGrey);;

  &:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    transition: 0.3s;
    border-radius: 50%;
    background: map-get($fontColors, mainGrey);
  }
}

input:checked + .slider:before {
  transform: translateX(22px);
  background: map-get($bgColors, secondaryPurple);
}

input:checked + .load:before {
  transform: translateX(22px);
  animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  background: map-get($bgColors, secondaryPurple);
}

input:checked + .slider {
  border: 3px solid map-get($bgColors, secondaryPurple);
}

input:checked + .load {
  border: 3px solid map-get($bgColors, secondaryPurple);
}