.main-container {
  .verify-signup-btn {
    background-color: map-get($btnBgColors, primaryGrey);
    color: map-get($fontColors, mainBlack);

    &:hover {
      color: map-get($fontColors, mainWhite);
    }
  }

  .content {
    .confirm-email-btn {
      background-color: map-get($btnBgColors, primaryPurple);
      width: fit-content;
      padding: 14px 28px;
      margin: 20px 0;
      color: map-get($fontColors, mainWhite);

      &:hover {
        background-color: map-get($btnBgColors, primaryBlack);
      }
    }

    .form-helper-bottom {
      text-align: start;
      margin: 0;
    }
  }
}
