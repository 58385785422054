.api-key-list-subheader-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .api-key-list-subheader-learn {
    font-family: $boldFont;;
    font-size: $fontMedium;
    line-height: 24px;
    letter-spacing: 0.01em;
    border-bottom: 0.5px solid map-get($fontColors, mainPurple);;
    color: map-get($fontColors, mainPurple);

    @media (max-width: $breakSm) {
      font-size: 11px;
    }
  }

  .api-key-list-subheader-create-new-key-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .api-key-list-subheader-create-new-key {
      font-family: $boldFont;;
      font-size: $fontMedium;
      line-height: 24px;
      letter-spacing: -0.01em;
      border-bottom: 0.5px solid #7346F3;
      color: map-get($fontColors, mainPurple);
      cursor: pointer;

      @media (max-width: $breakSm) {
        font-size: 11px;
      }
    }

    .key-icon {
      width: 20px;
      height: 20px;
      color: map-get($fontColors, mainPurple);
    }
  }
}