.menu-button {
  display: flex;
  flex-direction: row;
  padding: 12px 33px 12px 20px;
  border-radius: $borderRadius12;
  border: none;
  font-family: $boldFont;
  line-height: 22px;
  letter-spacing: -0.01em;
  transition: color, background .3s;
  gap: 8px;
  width: 100%;
  white-space: nowrap;
  background: map-get($bgColors, secondaryWhite);

  .button-icon-container {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    color: map-get($fontColors, mainPurple);
    background: map-get($bgColors, primaryWhite);
  }

  &.disabled {
    color: map-get($fontColors, mainGrey);
    position: relative;
    cursor: unset;
    background: unset;
  }
}

.active {
  .menu-button {
    color: map-get($fontColors, mainPurple);
    background: map-get($bgColors, primaryWhite);
  }
}

.tooltip-disabled-nav-link {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: 10px;
    height: 10px;
    background-color: #333;
    transform: rotate(45deg) translateX(-50%);
    display: none
  }

  &::before {
    content: attr(data-tooltip-for-disabled-nav-link);
    position: absolute;
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    width: 150px;
    text-align: center;
    z-index: 1000;
    display: none;
    font-family: $regularFont;
    font-size: $fontVerySmall;
    white-space: pre-wrap;
    max-height: 120px;
  }

  &:hover:before, &:hover:after {
    display: unset;
  }
}
