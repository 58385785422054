@import 'src/styles/variables.scss';

.container {
  padding-right: 3vw;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;

  .heading {
    font-family: $boldFont;
    font-size: $fontHeading;
  }
}