@import '../../styles/variables';

.container {

  .tableRow {
    display: grid;
    grid-template-columns: 50px 150px 1fr 1fr 100px;
    align-items: self-start;
    color: map-get($fontColors, mainGrey);
    font-size: $fontMedium;
    font-family: $mediumFont;
    border-radius: 12px;
    padding: 12px 0 12px 0;
  }
}

.centered {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .wideCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    color: map-get($fontColors, mainPurple);
  }
}

.expandedSubrowContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  border-left: 1px solid map-get($fontColors, mainGrey);
  margin-left: 10px;

  .infoIcon {
    width: 20px;
    height: 20px;
    color: map-get($fontColors, mainGrey);
    margin-left: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .expandedSubrowContentContainer {
    display: flex;
    justify-content: right;
    padding: 20px;
    color: map-get($fontColors, mainGrey);

    .expandedSubrow {
      width: calc(100% - 40px);
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.linkContainer {
  display: flex;
  gap: 4px;
  color: map-get($fontColors, mainPurple);
  align-items: center;
}