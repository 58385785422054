.tablesContainer {
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin-left: 4px;
}

.noAccountInfo {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}