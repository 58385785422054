.my-projects-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  box-shadow: $boxShadow;
  border-radius: $borderRadius12;
  cursor: pointer;
  aspect-ratio: .8;

  .card-image-wrapper {
    position: relative;
    width: 100%;
    height: 66%;
    background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);

    .sample-data-label-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 8%;
      right: 5%;
      width: 50%;
      height: 33px;
      max-width: 130px;
      border-radius: 8px;
      background: map-get($btnBgColors, primaryGreen);

      .sample-data-text {
        text-transform: uppercase;
        font-family: $boldFont;
        font-size: $fontVerySmall;
        line-height: 18px;
        letter-spacing: 0.1em;

        @media (max-width: $breakSm) {
          text-align: center;
        }
      }
    }
  }

  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 34%;
    gap: 5px;
    padding-left: 10%;
    background: map-get($bgColors, mainWhite);

    .card-description {
      font-family: $boldFont;
      font-size: 18.7865px;
      line-height: 28px;
      color: map-get($fontColors, mainBlack);
    }

    .card-users {
      font-family: $regularFont;
      font-size: $fontVerySmall;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: map-get($dashboardLight, settingsBg);
    }
  }
}

.add-card {
  cursor: pointer;
}

.add-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  background: map-get($bgColors, mainWhite);

  .add-card-text {
    font-family: $boldFont;
    font-size: $fontTitle;
    line-height: 28px;
    color: map-get($fontColors, mainGrey);
  }
}
.card-content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}