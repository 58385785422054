@import '../../styles/variables';

.outerContainer {
  width: 100%;
  padding-inline: 40px;

  .container {

    .innerContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: $fontMedium;

      .leftPart {
        display: flex;
        gap: 8px;

        .expander {
          display: flex;
          width: 20px;
          color: map-get($fontColors, mainGrey);
          justify-content: center;

          svg {
            width: 100%;
          }
        }

        .clickable {
          cursor: pointer;

          &:hover {
            color: map-get($fontColors, mainPurple);
          }
        }

        .title {
          font-family: $boldFont;
        }
      }

      .rightPart {
        .safe {
          color: map-get($fontColors, successText);
        }

        .unsafe {
          color: map-get($legendColors, alertRed);
        }
      }
    }
  }

  .entryBorder {
    border-bottom: 1.5px solid map-get($bgColors, secondaryGrey);
  }
}
