
/* Form helper bottom styles */

.form-helper-bottom {
  margin: 10px;
  text-align: center;

  .form-helper-bottom-link {
    color: map-get($fontColors, mainBlack);
    border-bottom: 1px solid map-get($fontColors, mainBlack);

    &:hover {
      color: map-get($fontColors, mainPurple);
    }
  }
}

/* Accept privacy policy in sign up page */

.accept-privacy-policy {
  text-align: center;
  margin: 12px 0 2px;
  padding: 0 25px;

  .form-helper-bottom-link {
    color: map-get($fontColors, mainBlack);
    border-bottom: 1px solid map-get($fontColors, mainBlack);
    transition: .3s;

    &:hover {
      color: map-get($fontColors, mainPurple);
      border-bottom: 1px solid map-get($fontColors, mainPurple);
    }

    &:visited {
      color: map-get($fontColors, mainBlack);

      &:hover {
        color: map-get($fontColors, mainPurple);
      }
    }
  }

  .accept-privacy-policy-link {
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
    color: inherit;

    &:hover {
      color: map-get($fontColors, mainPurple);
    }
  }
}

.arrow-icon {
  font-size: 1.2rem;
  position: relative;
  top: 4px;
  color: inherit;
}

.bottom-link {
  margin-top: 23px;
  letter-spacing: 0.02em;
}

.permission-box {
  display: flex;
  align-items: center;
  gap: 16px;

  .accept-permissions {
    color: map-get($fontColors, mainBlack);
    font-family: $mediumFont;
    line-height: 140%;
    letter-spacing: 0.28px;

    .accept-permissions-link {
      color: map-get($fontColors, mainBlack);
      border-bottom: 1px solid map-get($fontColors, mainBlack);
      transition: .3s;

      &:hover {
        color: map-get($fontColors, mainPurple);
        border-bottom: 1px solid map-get($fontColors, mainPurple);
      }
    }

    .addon-info {
      color: map-get($fontColors, mainPurple);
    }
  }

  .checkbox {
    .checkbox-checkmark {
      margin-right: 0;
    }
  }

  @media (max-width: $breakSm) {
    font-size: $fontVerySmall;
  }
}
