.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 23px;
  padding-right: 2px;

  .header-breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .header-back-icon {
    cursor: pointer;
    font-size: 35px;
    line-height: 1;
    font-family: $boldFont;;
  }

  .header-title {
    font-family: $boldFont;;
    font-size: 35px;
    line-height: 1;
    color: map-get($dashboardLight, settingsBg);
    text-transform: capitalize;
  }

  .header-addon {
    display: flex;
    align-items: center;
    padding: 15px 35px 16px 36px;
    gap: 8px;
    border-radius: $borderRadius12;
    background: map-get($bgColors, primaryWhite);
    color: map-get($fontColors, mainPurple);
    font-family: $boldFont;;
    line-height: 17px;
    letter-spacing: -0.01em;

    @media (max-width: $breakSm) {
      padding: 4px;
      font-size: $fontVerySmall;
      margin-top: 2px;
    }

    .header-arrow-left {
      transform: rotate(180deg);
      width: 28px;
      height: 28px;
      color: map-get($fontColors, mainGrey)
    }

    .calendar-icon {
      width: 20px;
      height: 20px;
      color: map-get($fontColors, mainPurple);
    }

    &.limit {
      font-size: $fontSizeRegular;
      color: map-get($fontColors, errorColor);
      border: 2px solid rgba(239, 3, 45, 0.20);
      background: map-get($btnBgColors, primaryRed);
      cursor: pointer;

      .info-icon {
        width: 20px;
        height: 20px;
        color: map-get($fontColors, errorColor);

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .mobile-header-logo-container {
    display: none;
  }

  @media(max-width: $breakSm) {
    &.header-container {
      display: flex;
      justify-content: center;
      margin-left: -4%;
      z-index: 2;

      .mobile-header-logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .header-breadcrumbs {
        display: none;
      }
    }
  }
}
