.checkbox {
  position: relative;
  width: fit-content;

  .checkbox-wrapper {
    width: auto;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
  }

  .checkbox-input {
    display: none;

    &:checked ~ .checkbox-checkmark {
      background-color: map-get($fontColors, mainPurple);
      border-color: map-get($fontColors, mainPurple);
    }
  }

  .checkbox-checkmark {
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1.5px solid map-get($fontColors, mainGrey);
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'system-ui';

    &:hover {
      background: map-get($bgColors, secondaryGrey);
    }
  }

  .checkbox-label {
    color: map-get($fontColors, mainGrey);
  }
}

