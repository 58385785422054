.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  position: absolute;
  padding: 23px 32px;
  border-radius: 12px;
  z-index: 99999;
  background: map-get($bgColors, mainWhite);
  box-shadow: 0 24px 44px 0 rgba(0, 0, 0, 0.18);
  top: -200px;
  right: 50%;
  transition: transform 0.4s ease;
  transform: translate(50%, 236px);

  .notification-text {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 4px;
    width: 260px;

    .notification-title {
      font-size: $fontMedium;
      font-family: $boldFont;
      color: map-get($dashboardLight, settingsBg);
    }

    .notification-description {
      font-family: $mediumFont;
      color: map-get($fontColors, mainGrey);
    }

    .close {
      position: absolute;
      top: -5px;
      right: 0;
      cursor: pointer;
      width: 20px;
      height: 20px;
      color: map-get($fontColors, mainGrey);
    }
  }

  @media (max-width: $breakSm) {
    .notification-text {
      width: 150px;

      .close {
        right: -15px;
      }
    }
  }
}