@import '../../styles/variables';


.container {
  width: 100%;

  .caspVaspTable {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;

    .tableHeader {
      display: grid;
      grid-template-columns: 50px 150px 1fr 1fr 100px;
      color: map-get($fontColors, mainBlack);
      font-size: $fontVerySmall;
      font-family: $boldFont;
      letter-spacing: 1.68px;
      text-transform: uppercase;
      margin-top: 22px;
      background: map-get($bgColors, secondaryWhite);
      padding: 6px 0 6px 0;
    }

    .tableBody {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}

.rowDivider {
  width: 100%;
  height: 0;
  border-bottom: 1.5px solid map-get($bgColors, secondaryGrey);
  margin-top: 4px;
}
