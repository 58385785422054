.custom-line-chart {
  height: 200px;
  width: 100%;

  .recharts-surface {
    background: map-get($bgColors, mainWhite);
  }
}

.chart-wrapper {
  background: map-get($bgColors, mainWhite);
  box-shadow: $boxShadow;
  padding: 28px 40px;
  border-radius: $borderRadius12;
  color: map-get($fontColors, mainGrey);

  .chart-header {
    display: flex;
    justify-content: space-between;
  }

  .chart-title {
    font-size: $fontVerySmall;
    font-family: $boldFont;
    text-transform: uppercase;
    letter-spacing: 0.14em;
    color: map-get($fontColors, mainBlack);
  }

  .chart-settings {
    display: flex;
    gap: 30px;
    align-items: center;

    @media (max-width: $breakSm) {
      gap: 10px;
    }

    .settings-icon {
      position: relative;
      cursor: pointer;
      width: 89px;
    }
  }

  .chart-content {
    margin-top: 20px;
    border-radius: $borderRadius12;
    height: 200px;
  }

  .info {
    height: 21px;

    @media (max-width: $breakSm) {
      display: none;
    }

    .info-icon {
      width: 20px;
      height: 20px;
    }
  }

  .detail-view-text {
    text-decoration-line: underline;
    text-underline-offset: 4px;
    color: map-get($fontColors, mainPurple);
    font-size: $fontSizeRegular;
    line-height: $fontSizeRegular;
    cursor: pointer;
  }
}

.custom-line-chart {
  font-size: $fontVerySmall;
  padding: 20px 0;
  background: map-get($bgColors, mainWhite);
  border-radius: $borderRadius12;

  @media (max-width: $breakSm) {
    padding: 5px 0;
  }

  path {
    stroke-linecap: round;
  }

  .line-chart-active-dot {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
}

.risk-score-chart {
  display: flex;
  justify-content: space-between;

  .score-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    justify-content: center;

    .score {
      font-size: 40px;
      font-family: $boldFont;;
      color: map-get($fontColors, mainGrey);

      @media (max-width: $breakSm) {
        font-size: $fontBilling;
      }

      .score-big {
        font-size: 60px;
        color: map-get($fontColors, mainBlack);

        @media (max-width: $breakSm) {
          font-size: 40px;
        }
      }
    }
  }

  .risk-chart-wrapper {
    padding-top: 28px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: map-get($bgColors, secondaryWhite);
    overflow: hidden;
  }

  .risk-score {
    font-size: $fontVerySmall;
    padding: 8px 18px;
  }
}

.custom-tooltip {
  background-color: map-get($dashboardLight, settingsBg);
  padding: 10px 16px;
  border-radius: 8px;

  .title {
    font-family: $regularFont;
    color: white;
  }

  .subtitle {
    font-size: 10px;
    color: map-get($fontColors, lightGrey)
  }
}
