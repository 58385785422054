.header-icon-div {
  margin-bottom: auto;

 @media (max-width: $breakSm) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;

   @media (max-width: $breakSm) {
      width: fit-content;

      .header-icon-future-ready {
        display: none;
      }
    }

    .header-icon-text-bc {
      width: 120px;
      margin: 0 8px;
    }

    .header-icon-future-ready {
      width: 100px;
    }

    .header-icon-logo {
      position: relative;

      .header-icon-hexagon {
        width: 33px;
      }

      .header-icon-bolt {
        position: absolute;
        left: 27%;
        top: 26%;
        width: 15px;
      }
    }
  }
}
