@import 'src/styles/variables';

/* Main Form styles */

.main-form {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;

  .main-form-input {
    padding: 15px 0 15px 20px;
    border: 1px solid map-get($btnBgColors, borderGrey);
    border-radius: $borderRadius10;
    font-size: 1rem;
  }

  &.create-new-project {
    width: 300px;
    margin-top: 20px;
  }

  &.sign-up-form {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .double-input-container {
      display: flex;
      justify-content: space-between;
      
      @media (max-width: $breakSm) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .main-form-input {
      margin: 0;
      border: 1.5px solid map-get($bgColors, borderGrey);
      padding: 13px 0 13px 31px;
    }

    .main-button {
      margin: 0;
    }

    .form-error-message {
      margin: 0;
    }

    @media (max-width: $breakSm) {
      gap: 10px;
    }
  }
}

/*Error messages styles */
.form-error-message {
  margin: 5px 0;
  padding: 0;
  color: map-get($fontColors, errorColor);
  font-size: 1rem;}

#signUpDiv {
  margin-top: 28px;
}

