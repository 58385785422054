@import "src/styles/variables";

.tableButton {
  display: flex;
  align-items: center;
  gap: 8px;
  color: map-get($fontColors, mainPurple);
  font-size: $fontMedium;
  font-family: $boldFont;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  background: none;
  border: none;

  &.redButton {
    color: map-get($fontColors, errorColor);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
}