.horizontal-bar-chart-wrapper {
  height: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;

  .column-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 10px;
  }

  .bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    justify-content: flex-end;
    gap: 10px;
  }

  .bar-icon {
    height:  28px;
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .bar {
    width: 10px;
    border-radius: 6px;
  }
}
