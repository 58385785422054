.page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  background: map-get($bgColors, secondaryWhite);

  .page-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .page-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 25px;
      padding: 2px 3vw 2px 2px;
      overflow-y: auto;
    }
  }

  @media (max-width: $breakSm) {
    &.page-container {
      padding: 4%;

      .page-content {
        margin-top: 50px;
        overflow-y: unset;
      }
    }
  }
}
