.risk-score-chart-border-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  position: relative;
  background:conic-gradient(from 180deg at 50% 50%, rgba(109, 255, 150, 1) 0%, rgba(109, 255, 150, 1) 28%, rgba(109, 255, 150, 1) 48%, rgba(255, 214, 109, 1) 56%, rgba(255, 214, 109, 1) 75%, rgba(234, 14, 93, 1) 78%, rgba(234, 14, 93, 1) 100%);

  .risk-score-circle-chart {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    background: #FAFAFA;

    .risk-score-pointer {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 80px;
      width: 1px;
      border-radius: 1px;
      transform-origin: bottom center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 9px;
        height: 9px;
        left: 50%;
        background: transparent;
        border-radius: 50%;
        border: 7px solid map-get($bgColors, mainWhite);
        box-shadow: 1px -5px 9px rgba(0, 0, 0, 0.23);
        transform: translate(-50%, -50%);
      }
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 44px;
      height: 44px;
      background: map-get($bgColors, secondaryWhite);
      border-radius: 50%;
      border: 1.5px solid map-get($fontColors, mainGrey);
    }

    &::before {
      content: '';
      position: absolute;
      background: map-get($bgColors, secondaryWhite);
      width: 87px;
      height: 87px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    }

    .risk-score-scale-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      width: 100%;
      bottom: -10px;
      font-family: $regularFont;
      font-size: $fontVerySmall;
      line-height: 18px;
    }
  }
}
