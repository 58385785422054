.menu-user-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: $borderRadius12;
  padding: 12px 14px 12px 20px;
  gap: 8px;
  transition: background, color .3s;
  font-family: $boldFont;;
  line-height: 22px;
  letter-spacing: -0.01em;
  cursor: pointer;
  max-width: 150px;
  overflow: hidden;

  &:hover {
    background: map-get($bgColors, primaryWhite);
    color: map-get($fontColors, mainPurple);
  }

  &.active {
    color: map-get($fontColors, mainPurple);
    background: map-get($bgColors, primaryWhite);
  }

  .first-icon {
    width: 20px;
    height: 20px;
    color: map-get($fontColors, mainBlack);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .second-icon {
    width: 4px;
    height: 19px;
    color: map-get($fontColors, midGrey);

    svg {
      width: 4px;
      height: 19px;
    }
  }
}
