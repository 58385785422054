.settings-modal {
  position: absolute;
  background-color: map-get($dashboardLight, settingsBg);
  color: white;
  top: 38px;
  padding: 16px 32px;
  border-radius: 8px;
  transform: translateX(calc(-50% + 4px));
  z-index: 1;

  &:before {
    content: '';
    top: 0;
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: map-get($dashboardLight, settingsBg);
    transform: rotate(45deg) translateX(-50%);
    border-radius: 2px;
    left: 50%;
  }

  @media (max-width: $breakSm) {
    padding: 16px 20px;
  }
}

.settings-options {
  text-align: center;

  .setting-option {
    padding: 9px 0;
    font-family: $regularFont;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: map-get($fontColors, mainWhite);
    border-bottom: 1.5px solid rgba(233, 233, 233, 0.1);
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &.hide-widget {
      color: map-get($fontColors, secondaryRed);
    }
  }
}