@import "../../styles/variables";

.payoutsTableContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 80px;

  .tableHeader {
    display: grid;
    grid-template-columns: 13% 20% 8% 8% 11% 40%;
    text-transform: uppercase;
    color: map-get($fontColors, mainBlack);
    font-size: $fontVerySmall;
    font-family: $boldFont;
    letter-spacing: 1.68px;
    background: map-get($bgColors, secondaryWhite);
    padding: 6px 0 6px 0;
  }

  .tableRow {
    display: grid;
    grid-template-columns: 13% 20% 8% 8% 11% 40%;
    color: map-get($fontColors, mainGrey);
    font-size: $fontMedium;
    font-family: $mediumFont;
    text-transform: capitalize;

    .rowDataContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .dataCellRow {
        display: flex;
        justify-content: center;
      }

      .strong {
        font-family: $boldFont;
      }

      .dark {
        color: map-get($fontColors, mainBlack);
      }
    }
  }
}

.total {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: map-get($fontColors, mainGrey);
  font-size: $fontSizeRegular;
}