.shadow-box {
  box-shadow: $boxShadow;
  display: flex;
  border-radius: $borderRadius12;
  margin-left: 2px;
}

.billing {
  overflow: auto;

  .divider {
    margin: 38px 0;
    width: 100%;
    border: 1.5px solid map-get($bgColors, secondaryGrey);
  }

  .recharts-cartesian-axis-line, .recharts-cartesian-axis-tick-line {
    stroke: white;
  }

  .recharts-cartesian-axis-tick-value {
    font-size: 10px;
  }

  .recharts-bar-rectangle {
    path {
      clip-path: inset(1px 1px round 5px);
    }
  }
}

.management-boxes-container {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.billing-management-box-container {
  display: flex;
  flex: 1;
}

.management-box {
  width: 100%;
  padding: 21px 40px 20px;
  align-items: center;
  justify-content: space-between;
}

.manage-plan-add-on-container {
  width: 100%;
  margin-bottom: 37px;
}

.management-box-content {
  display: flex;
  align-items: center;
  gap: 30px;
}

.management-box-table {
  display: flex;
  gap: 52px;
  margin-left: 18px;
}

.management-box-element-label {
  font-size: $fontVerySmall;
  font-family: $boldFont;
  color: map-get($fontColors, mainGrey);
  text-transform: uppercase;
  letter-spacing: 0.14em;
  line-height: 18px;
}

.management-box-element-value {
  font-family: $boldFont;
  font-size: $fontBilling;
  line-height: 35px;
  margin-right: 9px;
}

.management-box-element {
  display: flex;
  flex-direction: column;
}

.billing-link-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: map-get($fontColors, mainPurple);
  text-decoration: underline;
  cursor: pointer;
  font-size: $fontMedium;

  path {
    stroke: map-get($fontColors, mainPurple);
  }

  .edit-icon {
    width: 20px;
    height: 20px;
  }
}

.billing-button-wrapper {
  width: 215px;

  .main-button {
    margin: 0;

    .icon {
      svg {
        width: 20px;
      }
    }
  }
}

.plan-status {
  display: flex;
  align-items: center;
}

.status-dot {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  margin-right: 8px;
}

.billing-banner-container {
  background: url('../../assets/images/png/bannerBg.png');
  background-size: cover;
  height: 93px;
  min-height: 80px;
  border-radius: $borderRadius12;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3vw;
}

.billing-banner-product-name {
  color: map-get($fontColors, mainWhite);
  font-size: $fontVerySmall;
  font-family: $boldFont;
  line-height: normal;
  letter-spacing: 1.68px;
  text-transform: uppercase;
}

.billing-banner-text {
  color: map-get($fontColors, mainWhite);
  font-size: 26px;
  line-height: 106%;
  letter-spacing: -0.01em;
  font-family: $xBoldFont;
}

.banner-button {
  font-size: $fontVerySmall;
  letter-spacing: 0.1em;
  height: 33px;
  font-family: $boldFont;
  padding: 7px 17px 8px 17px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.banner-link {
  font-family: $boldFont;
  font-size: $fontLarge;
  color: map-get($fontColors, mainWhite);
  width: 133px;
  border-bottom: 2px solid map-get($fontColors, mainWhite);
  cursor: pointer;
}

.api-usage {
  height: auto;
  padding: 28px 40px 31px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .line-chart-active-dot {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }

  .recharts-cartesian-axis-line, .recharts-cartesian-axis-ticks, .recharts-cartesian-axis-tick-line {
    stroke: map-get($bgColors, secondaryWhite);
  }

  .recharts-cartesian-axis-tick-value {
    fill: map-get($fontColors, mainGrey);
    font-size: 10px
  }

  .horizontal-bar-chart-wrapper {
    height: 300px;
  }

  .menu-dash {
    transform: rotate(270deg);
    height: 14px;
    width: 14px;
  }
}

.api-usage-page {
  padding: 28px 0 31px;
  margin-bottom: 40px;
  width: 100%;

  .pagination-container {
    width: 80%;
    left: 0;
    transform: unset;
  }

  .select-with-checkbox-group {
    width: 100%;
    margin-top: 16px;
  }

  .button {
    padding: 13px 20px;
  }
}

.billing-api-usage {
  height: auto;
  padding: 28px 40px 31px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.api-usage-range {
  display: flex;
  align-items: center;
  padding: 3px 7px 4px 12px;
  cursor: pointer;

  &:hover {
    background-color: map-get($btnBgColors, primaryGrey);
  }

  &:hover, &.active {
    background-color: map-get($btnBgColors, primaryGrey);
    border-radius: 50px;
  }
  svg {
    height: 14px;
    width: 14px
  }
}

.api-usage-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.api-usage-title {
  font-size: $fontVerySmall;
  font-family: $boldFont;
  text-transform: uppercase;
  letter-spacing: 0.14em;
  line-height: 18px;
}

.api-usage-options {
  display: flex;
  gap: 21px;
  position: relative;

  .tooltip {
    &:hover {
      svg {
        path {
          stroke: map-get($fontColors, mainBlack);
        }
      }
    }
  }

  svg {
    path {
      stroke: map-get($fontColors, mainGrey);
    }
  }

  .settings-modal {
    padding: 16px 19px 19px;
    transform: none;
  }

  .settings-options {
    width: 86px;
    text-align: center;
  }

  .info-icon {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.api-usage-text {
  font-size: $fontSizeRegular;
  line-height: 21px;
  font-family: $mediumFont;
  color: map-get($fontColors, mainGrey);
  margin-right: 6px;
}

.api-usage-footer {
  display: flex;
  justify-content: space-between;
}

.chart-label {
  font-size: 10px;
  text-align: center;
  width: 20px;
}

.billing-api-usage {
  height: 352px;
}

.api-usage-table-header {
  display: grid;
  grid-template-columns: 21% 21% 26% 15% 17%;
}

.api-usage-table-cell {
  font-size: $fontMedium;
  line-height: 18px;
  display: flex;
  align-items: center;

  .api-usage-table-value {
    color: map-get($fontColors, mainBlack);
  }
}

.api-usage-table-value {
  font-size: $fontMedium;
}

.api-usage-table-row {
  display: grid;
  grid-template-columns: 21% 21% 26% 15% 17%;
  align-items: center;
  height: 56px;
  border-bottom: solid #E9E9E9 2px;
  color: map-get($fontColors, mainGrey);
  font-family: $mediumFont;
}

.api-usage-no-data-row {
  display: grid;
  height: 56px;
  color: map-get($fontColors, mainGrey);
  font-family: $mediumFont;
  align-items: center;
}

.api-usage-table {
  height: auto;
  padding: 28px 40px 31px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .api-usage-title {
    margin-bottom: 16px;
  }
}

.danger {
  .management-box-element-label, .management-box-element-value {
    color: map-get($fontColors, errorColor);
  }

  path {
    stroke: map-get($fontColors, errorColor);
  }

  .api-usage-table-value {
    color: map-get($fontColors, errorColor);
    margin-right: 6px;
  }
}

.api-usage-content {
  display: flex;
  padding-right: 44px;
  .left-side {
    width: 80%;
    margin-right: 37px;
  }
}
.select {
  margin-top: 16px
}
.api-usage-filter-header {
  font-family: $boldFont;
  font-size: $fontMedium;
  line-height: 24px;
}

.billing-manage-plan-container {
  width: 100%;
  padding: 47px;
}

.billing-banner-manage-plan-button-text {
  font-family: $boldFont;
}

.auto-width {
  width: auto;
}
