.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: inherit;
  transform: translate(50%, 50%);

  @media (max-width: $breakSm) {
    transform: translateX(-50%);
  }
}

.relative {
  left: 0;
  position: relative;
  transform: translateX(0);
}
