@import "src/styles/variables";

  .inviteNewUsers {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      height: 100%;
      max-width: 503px;
      text-align: center;

      .title {
        color: map-get($dashboardLight, settingsBg);
        font-size: $fontBannerSmall;
        font-family: $boldFont;
        line-height: 35px;
      }

      .description {
        color: map-get($fontColors, mainGrey);
        font-size: $fontLarge;
        font-family: $mediumFont;
        line-height: 140%;
        letter-spacing: 0.36px;
      }

      .buttonContainer {
        margin-top: 24px;
        width: 300px;
      }
    }
  }

.inviteModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 31px;
  max-width: 382px;

  .modalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .title {
      color: map-get($dashboardLight, settingsBg);
      font-size: $fontBannerSmall;
      font-family: $boldFont;
      line-height: 35px;
    }
  }

  .modalTitleIconAbove {
    flex-direction: column;
  }

  .modalDescription {
    color: map-get($fontColors, mainGrey);
    text-align: center;
    font-family: $mediumFont;
    font-size: $fontLarge;
    line-height: 140%;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 16px;

    .formInput {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 5px 5px 5px 20px;
      height: 54px;
      border-radius: 10px;
      border: 2px solid map-get($bgColors, borderGrey);
    }

    .inviteUsersSelect {
      margin: 0;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background: map-get($bgColors, primaryWhite);
  color: map-get($fontColors, mainPurple);
  padding: 12px;
  width: 44px;
}

.alertIcon {
  background: map-get($btnBgColors, primaryRed);
}

.managementTable {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-height: 0;

  .tableHeader {
    display: flex;
    justify-content: space-between;
  }

  .table {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    margin-bottom: 21px;
    margin-left: 2px;
    padding: 28px 40px 0 40px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: map-get($bgColors, mainWhite);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
    min-height: 0;
    overflow-y: auto;

    .tableSubheader {
      display: grid;
      grid-template-columns: 28% 23% 20% 15% 14%;
      margin-bottom: 20px;

      .subheaderItem {
        display: flex;
        gap: 8px;
        color: map-get($fontColors, mainBlack);
        font-size: $fontVerySmall;
        font-family: $boldFont;
        letter-spacing: 1.68px;
        text-transform: uppercase;
        align-items: center;
      }

      .subheaderIcon {
        width: 20px;
        height: 20px;
      }
    }

    .tableRow {
      display: grid;
      grid-template-columns: 28% 23% 20% 15% 14%;
      padding-top: 29px;
      padding-bottom: 27px;
      border-bottom: 1.5px solid map-get($bgColors, secondaryGrey);

      &:last-of-type {
        border-bottom: none;
      }

      .item {
        color: map-get($fontColors, mainGrey);
        font-size: $fontMedium;
        font-family: $regularFont;
      }
    }
  }
}

.errorMessage {
  color: map-get($fontColors, errorColor);
}
