/* Heading title based on Form components */

.heading-title {
  margin: 0;
  padding: 0;
  font-family: $xBoldFont;
  font-size: 36px;
}

.heading-title-text {
  color: map-get($fontColors, mainGrey);
  font-size: $fontMedium;
  font-family: $mediumFont;
  line-height: 140%;
  margin: 14px 0 23px;
  letter-spacing: 0.32px;

  .heading-title-text-link {
    text-decoration: none;
    color: map-get($fontColors, mainBlack);
    border-bottom: 1px solid map-get($fontColors, mainBlack);
    margin-left: 4px;
    transition: color 0.2s;

    &:hover {
      color: map-get($fontColors, mainPurple);
    }
  }
}

.arrow-icon {
  font-size: 1.2rem;
  position: relative;
  top: 4px;
  color: inherit;
}