@import '../../styles/variables.scss';

.select-with-checkbox-group {
  position: relative;
  cursor: pointer;

  .button {
    all: unset;
    border-radius: 10px;
    border: 2px solid map-get($bgColors, borderGrey);
    padding: 17px 20px;
    line-height: 20px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dash-icon {
    width: 20px;
    height: 20px;
    rotate: -90deg;
  }

  .selected {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .close-icon-wrapper {
    all: unset;
    cursor: pointer;
    background: map-get($bgColors, primaryWhite);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-icon {
      display: flex;
      width: 10px;
      height: 10px;
      color: map-get($fontColors, mainPurple);
    }
  }

  .placeholder {
    color: map-get($fontColors, mainGrey);
    min-height: 24px;
  }

  .options-wrapper {
    width: 200px;
    position: absolute;
    border-radius: 8px;
    top: calc(100% + 8px);
    left: 8px;
    right: 8px;
    padding: 20px;
    background: map-get($bgColors, mainWhite);
    z-index: 10;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
  }

  .no-outline {
    display: flex;
    gap: 6px;
    align-items: center;
    color: map-get($fontColors, mainGrey);
    width: auto;

    .counter {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 6px;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border-radius: 12px;
      background: map-get($bgColors, primaryWhite);
      font-family: $boldFont;
      font-size: $mediumFont;
      color: map-get($fontColors, mainPurple);
    }

    .label {
      font-family: $boldFont;
      font-size: $fontMedium;
      letter-spacing: -0.16px;
      white-space: nowrap;
    }

    .label-active {
      color: map-get($fontColors, mainPurple);
      text-decoration-line: underline;
    }

    .dash-active {
      color: map-get($fontColors, mainPurple);
    }
  }
}