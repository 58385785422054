.widgets-subtitle {
  font-family: $boldFont;;
  font-size: $fontMedium;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: map-get($dashboardLight, settingsBg);
}

.widgets-page-container {
  display: flex;

  .widgets-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding-bottom: 30px;

    .widget-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 27px;
      padding-right: 32px;
      margin-bottom: 10px;
      width: 100%;
      min-height: 80px;
      height: 10%;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: $boxShadow;
      border-radius: $borderRadius12;

      .widget-icon-box {
        display: flex;
        align-items: center;
        gap: 20px;

        .widget-name {
          font-family: $boldFont;
          font-size: $fontBilling;
          line-height: 35px;
          color: map-get($fontColors, mainBlack);
          letter-spacing: 1px;
        }

        svg {
          width: 35px;
          height: 29px;

          path {
            stroke: map-get($fontColors, mainBlack);
          }
        }
      }

      .toggle-switch-container {
        display: flex;
        align-items: center;
        gap: 16px;

        .info-icon {
          width: 20px;
          height: 20px;
          color: map-get($fontColors, mainGrey);

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      @media (max-width: $breakSm) {
        &.widget-box {
          .widget-icon-box {
            margin-right: 2px;
          }

          .tooltip {
            display: none;
          }

          .widget-name {
            font-size: $fontVerySmall;
            line-height: unset;
          }

          svg {
            width: 24px;
          }
        }
      }
    }

    @media (max-width: $breakSm) {
      justify-content: flex-start;
    }
  }

  .widget-banner-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    gap: 16px;
    margin-left: 40px;
    padding-left: 68px;
    padding-right: 72px;
    width: 470px;
    min-width: 470px;
    height: 344px;
    box-shadow: $boxShadow;
    border-radius: $borderRadius12;
    color: map-get($fontColors, mainWhite);
    position: relative;

    .widget-banner-add-on {
      font-family: $xBoldFont;
      font-size: $fontVerySmall;
      line-height: 18px;
      letter-spacing: 0.14em;
      text-transform: uppercase;
    }

    .widget-banner-main-text {
      font-family: $xBoldFont;
      font-size: 40px;
      line-height: 106%;
      letter-spacing: -0.01em;
    }

    .world-tag {
      padding: 7px 17px 8px;
      position: absolute;
      color: map-get($fontColors, mainBlack);
    }

    a {
      font-family: $regularFont;
      font-size: $fontLarge;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: map-get($fontColors, mainWhite);
      border-bottom: 1px solid map-get($fontColors, mainWhite);
      width: fit-content;
    }

    @media (max-width: $breakSm) {
      display: none;
    }
  }
}
