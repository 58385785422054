@import 'variables';
@import 'dashboard';
@import 'pageNotFound';
@import 'signUp';
@import 'banners';
@import 'src/components/buttons/menu-button/menuButton';
@import 'src/components/buttons/menu-user-button/userButton';
@import 'src/components/card/card';
@import 'src/components/analytics-views/analytics-views';
@import 'src/components/billing/billing';
@import 'src/components/modals/modal';
@import 'src/components/modals/create-project-modal/createProjectModal';
@import 'src/components/modals/delete-modals/deleteModal';
@import 'src/components/modals/users-modal/usersModal';
@import 'src/components/header/header';
@import 'src/components/page-main-container/pageContainer';
@import 'src/components/sidebar/sidebar';
@import 'src/components/charts/charts';
@import 'src/components/charts/custom-bar-chart-component/horizontal-bar-chart-component';
@import 'src/components/charts/custom-bar-chart-component/vertical-chart';
@import 'src/components/footer/footer';
@import 'src/components/form/form-bottom-link/formBottomLink';
@import 'src/components/form/form-header/formHeader';
@import 'src/components/loaders/loader/loader';
@import 'src/components/loaders/loading-spinner/loadingSpinner';
@import 'src/components/NFT-collection/nftCollection';
@import 'src/components/page-header/pageHeader';
@import 'src/components/toggle-switch/toggleSwitch';
@import 'src/components/popups/analyticsSetting';
@import 'src/components/buttons/buttons';
@import 'src/components/risk-score/risk-score';
@import 'src/components/risk-score/risk-score-chart/risk-score-chart';
@import 'src/components/no-data-container/noData';
@import 'src/components/pagination-container/paginationContainer';
@import 'src/components/mobile/hamburger-menu/hamburgerMenu';
@import 'src/components/mobile/screen-too-small/smallDeviceNotification';
@import 'src/components/select/select';
@import 'src/components/checkbox/checkbox';
@import 'src/components/checkbox-group/checkbox-group';
@import 'src/components/selectWithCheckboxGroup/select-with-checkbox-group';
@import 'src/components/copy-to-clipboard-tooltip/copyToClipboardTooltip';
@import 'src/components/notification/notification';
@import 'src/components/sidebar/sidebar-alert/sidebarAlert';
@import 'src/components/AuthForms/form';
@import 'src/components/ApiKeysList/apiKeysList';
@import 'src/layouts/dashboard-layout/dashboardLayout';
@import 'src/pages/dashboard/dashboard';
@import 'src/pages/my-account/myAccount';
@import 'src/pages/my-projects/myProjects';
@import 'src/pages/single-project-api-keys/apiKeys';
@import 'src/pages/single-project-users/users';
@import 'src/pages/single-project-analytics/analytics';
@import 'src/pages/single-project-widgets/widgets';
@import 'src/pages/project-settings/projectSettings';
@import 'src/pages/onboarding-article/onboardingArticle';
@import 'src/pages/billing/billing';
@import 'src/pages/single-project-explorer/explorer';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol, ul {
  padding-left: 50px;
}

#root {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

button {
  cursor: pointer;
}

body {
  font-family: $regularFont;
  font-size: $fontSizeRegular;
}

a {
  text-decoration: none;
}


// scrollbar

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: map-get($btnBgColors, primaryGrey);
  position: absolute;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: map-get($btnBgColors, primaryGrey);
}

*::-webkit-scrollbar-thumb {
  border-radius: $borderRadius10;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: map-get($btnBgColors, primaryBlack);
}

.tooltip {
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #333;
    transform: rotate(45deg) translateX(-50%);
    display: none
  }

  &::before {
    content: attr(data-tooltip);
    position: absolute;
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 10px;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 128px;
    min-height: 38px;
    text-align: center;
    z-index: 1000;
    display: none;
    font-family: $regularFont;
    font-size: $fontVerySmall;
  }

  &:hover:before, &:hover:after {
    display: unset;
  }
}
