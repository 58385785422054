.plan-selector-container {
  width: 100%;
  padding: 15px 40px 0;

  .plan-selector-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;

    .account-plan-heading-title {
      font-size: $fontVerySmall;
      font-family: $boldFont;
      text-transform: uppercase;
      letter-spacing: 0.14em;
      line-height: 18px;
    }

    .centered-horizontal {
      margin-left: auto;
      margin-right: auto;
    }

    .frequency-toggle-container {
      display: flex;

      .centered-vertical {
        margin-top: auto;
        margin-bottom: auto;
      }

      .selected {
        display: flex;
        color: map-get($btnBgColors, primaryPurple);
      }

      .unselected {
        display: flex;
        color: map-get($fontColors, mainGrey);
      }

      .frequency-toggle-checkbox-container {
        margin: auto 15px;
      }

      .upside-down {
        transform: rotate(180deg);
      }

      .save-badge-container {
        margin-left: 15px;

        .save-badge {
          font-family: $boldFont;
          font-size: 9px;
          display: flex;
          padding: 6px 8px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 8px;
        }

        .save-badge-inactive {
          background: map-get($bgColors, primaryWhite);
          color: map-get($fontColors, mainPurple)
        }

        .save-badge-active {
          background: map-get($btnBgColors, primaryPurple);
          color: map-get($fontColors, mainWhite)
        }
      }

      .frequency-tooltip-container {
        margin-left: 15px;

        .info-icon {
          width: 20px;
          height: 20px;
          color: map-get($fontColors, mainGrey);

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .plan-selector-cards-container {
    display: flex;
    justify-content: center;

    .plan-card-container {
      flex: 1;
      display: flex;
      max-width: 360px;
      padding: 40px;
      margin-inline: 10px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      border-radius: 12px;
      border: 1.5px solid map-get($bgColors, secondaryGrey);
      background: map-get($bgColors, mainWhite);
    }

    .plan-card-container-contact-only {
      border: 1.5px solid map-get($billingColors, cardBorder);
      background: map-get($billingColors, cardBackground);
    }

    .plan-card-container-most-popular {
      border: 2px solid map-get($btnBgColors, primaryPurple);
    }

    .most-popular-badge {
      display: inline-flex;
      padding: 7px 17px 8px 17px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-top: -60px;
      text-transform: uppercase;
      color: map-get($fontColors, mainPurple);
      font-family: $boldFont;
      font-size: $fontSizeRegular;
      border: 2px solid map-get($btnBgColors, primaryPurple);
      border-radius: 8px;
      background: map-get($bgColors, mainWhite);
    }

    .plan-card-name-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .plan-card-name-text {
        font-family: $boldFont;
        font-size: $fontBilling;
      }
    }

    .info-box {
      width: 167px;
      height: 50px;

      .contact-only-message {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: $fontBilling;
        color: map-get($fontColors, mainGrey);
      }
    }

    .price-info-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .plan-card-price {
        font-family: $boldFont;
        font-size: 40px;
        line-height: 35px;
        margin-right: 8px;
      }

      .plan-card-frequency {
        color: map-get($fontColors, mainGrey);
        font-family: $boldFont;
        font-size: $fontVerySmall;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.68px;
        text-transform: uppercase;
      }
    }

    .plan-feature-container {
      display: flex;
      font-size: $fontMedium;
      margin-bottom: 8px;

      .plan-feature-icon-container {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .sentiment-active {
        color: map-get($fontColors, mainPurple);
      }

      .plan-feature-name {
        margin-left: 8px;
      }

      .plan-feature-text-disabled {
        font-size: $fontMedium;
        color: map-get($fontColors, mainGrey)
      }
    }

    .book-a-call {
      font-size: $fontMedium;
      line-height: normal;
      letter-spacing: -0.16px;
      text-decoration-line: underline;
      cursor: pointer;
      margin-top: auto;
    }

    .book-a-call-active {
      color: map-get($fontColors, mainPurple);
    }

    .book-a-call-inactive {
      color: map-get($fontColors, mainBlack);
    }
  }
}

.plan-button-text {
  text-transform: uppercase;
}

.downgrade-plan-button {
  display: flex;
  color: map-get($fontColors, mainPurple);

  svg {
    width: 23px;
    height: 23px;
  }
};

.plan-button {
  padding: 12px 20px !important;
}

.plan-limit-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 31px 103px;
  margin-bottom: 40px;
  color: map-get($fontColors, errorColor);
  font-size: $fontLarge;
  border-radius: 12px;
  border: 2px solid rgba(239, 3, 45, 0.20);
  background: map-get($btnBgColors, primaryRed);

  .limits-reached {
    display: flex;
    align-items: center;
    gap: 13px;
    font-size: $fontBilling;
    font-family: $xBoldFont;
    letter-spacing: -0.2px;

    .info-icon {
      width: 32px;
      height: 32px;
      color: map-get($fontColors, errorColor);

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .upgrade-text {
    color: map-get($fontColors, errorColor);
    font-size: $fontLarge;
    font-family: $mediumFont;
    letter-spacing: -0.18px;
  }

  .upgrade-button {
    background: none;
    border: none;
    color: map-get($fontColors, errorColor);
    font-size: $fontLarge;
    font-family: $boldFont;
    letter-spacing: 0.18px;
    text-decoration-line: underline;
    text-underline-offset: 4px;
  }
}