@import '../../styles/variables';

.outerContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 32px;
    font-size: $fontLarge;
    width: 50%;
    max-width: 800px;
    text-align: justify;
  }

  @media (max-width: $breakMd) {
    .container {
      width: 70%;
    }
  }

  @media (max-width: $breakSm) {
    .container {
      width: 90%;
      margin-left: 20px;
    }
  }
}