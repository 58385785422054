.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 104px;
  max-width: 230px;
  width: 100%;
  height: 100%;
  border-right: 2px solid #E9E9E9;
  margin-right: 3vw;
  transition: max-width .3s;

  .menu-logo-container {
    margin-bottom: 35px;
  }

  .menu-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 180px;
    gap: 8px;

    a {
      text-decoration: none;
      width: 100%;
      height: 46px;
    }
  }

  .profile-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: auto;
    max-width: 150px;
    gap: 29px;

    .user-button-description {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .settings-popup {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 144px;
      height: 95px;
      background: map-get($dashboardLight, settingsBg);
      box-shadow: $boxShadowSecondary;
      border-radius: 8px;
      margin-bottom: 19px;
      transition: max-width .3s;

      .settings-my-account {
        color: map-get($fontColors, mainWhite);
        cursor: pointer;
      }

      .settings-log-out {
        color: map-get($fontColors, secondaryRed);
        cursor: pointer;
      }

      .divider {
        height: 1.5px;
        width: 70px;
        background: rgba(233, 233, 233, 0.1);
        margin: 9px 0;
      }

      &:before {
        content: '';
        bottom: -7px;
        position: absolute;
        width: 18px;
        height: 18px;
        background: map-get($dashboardLight, settingsBg);
        transform: rotate(45deg);
        border-radius: 2px;
      }
    }
  }

  &.collapsed {
    max-width: 104px;

    .menu-button {
      padding: 12px;
      border-radius: 50%;
      height: 46px;
    }

    .menu-button-description {
      display: none;
    }

    .menu-dash {
      transform: rotate(180deg);
    }

    .menu-user-button {
      padding: 12px;
      border-radius: 50%;
      margin-bottom: 20px;

      .user-button-description {
        display: none;
      }

      .user-button-dashes {
        display: none;
      }
    }

    .settings-open {
      max-width: 90px;
    }

    .tooltip {
      cursor: auto;

      &::after {
        position: absolute;
        top: 10px;
        left: 100%;
        transform: rotate(45deg) translateX(100%);
      }

      &::before {
        padding: 7px 16px 10px 18px;
        top: 4px;
        left: 13px;
        min-width: 75px;
        transform: translateX(40%);
        width: auto;
        min-height: unset;
        max-height: 34px;
      }
    }
  }

  &:not(.collapsed) {
    .tooltip {
      &:hover:before, &:hover:after {
        display: none;
      }
    }
  }

  @media(max-width: $breakSm) {
    &.menu-container {
      max-width: 280px;
      padding: 20px 0;
      position: fixed;
      z-index: 4;
      background: map-get($bgColors, mainWhite);
      top: 0;
      left: -280px;
      transition: transform .3s;
      margin-right: 0;

      &.mobile-menu-open {
        display: flex;
        background: map-get($bgColors, mainWhite);
        transform: translateX(280px);
      }

      .dash-icon-button {
        display: none;
      }
    }
  }
}

.black-icon {
  stroke: black
}
