/* API keys list styles */

.api-keys-main-container {
  box-sizing: border-box;
  padding: 28px 44px 28px 40px;
  position: relative;
  box-shadow: $boxShadow;
  border-radius: $borderRadius12;
  background: map-get($bgColors, mainWhite);
  flex: 1;

  @media (max-width: $breakSm) {
    padding: 6% 2%;
    height: fit-content;
    border-radius: $borderRadius26;
  }

  .api-keys-error-message {
    margin: 5px 0;
    padding: 0;
    color: map-get($fontColors, errorColor);
    font-size: 1rem;
    position: absolute;
    bottom: 0;

    @media (max-width: $breakSm) {
      font-size: 0.9rem;
    }
  }

  .api-keys-table-edit-text-input {
    background-color: inherit;
    width: 70%;
  }

  .api-keys-list-welcoming {
    color: map-get($fontColors, mainGrey);
  }

  h3 {
    font-family: $mediumFont;
    margin-bottom: 2%;
  }

  .api-keys-heading-titles {
    display: grid;
    grid-template-columns: 17% 22% 17% repeat(3, 1fr) 3%;
    width: 100%;
    margin-bottom: 25px;

    &.api-keys-public-heading {
      grid-template-columns: 10% 29% 17% repeat(3, 1fr) 3%;

      @media (max-width: $breakSm) {
        grid-template-columns: 20% 33% 33% 4%;
      }
    }

    &.api-keys-heading-titles > * {
      font-family: $boldFont;;
      font-size: $fontVerySmall;
      line-height: 18px;
      letter-spacing: 0.14em;
      color: map-get($fontColors, mainBlack);
      text-transform: uppercase;
    }

    .copy {
      margin-left: 5px;
    }

    @media (max-width: $breakSm) {
      grid-template-columns: 20% 33% 33% 4%;

      .last-used, .expiration, .created {
        display: none;
      }

      .copy {
        display: flex;
        align-items: center;
      }
    }
  }

  .key-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8%;

    .key-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      color: map-get($fontColors, mainBlack);
    }

    .input-wrapper {
      overflow: hidden;
    }
  }

  .value-container {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .api-keys-table-wrapper {
    .api-keys-body {
      .api-keys-table-row {
        display: grid;
        grid-template-columns: 17% 22% 17% repeat(3, 1fr) 3%;
        color: map-get($fontColors, mainGrey);
        width: 100%;
        font-size: 1rem;
        font-family: inherit;
        padding-top: 29px;
        padding-bottom: 27px;
        border-top: 1.5px solid #E9E9E9;
        border-bottom: 1.5px solid #E9E9E9;

        &.public-keys-table-row {
          grid-template-columns: 10% 29% 17% repeat(3, 1fr) 3%;

          @media (max-width: $breakSm) {
            font-size: 0.9rem;
            grid-template-columns: 22% 34% 32% 12%;

            .expiration, .last-used-at, .created-at{
              display: none;
            }
          }
        }

        &:first-child {
          border-top: none;
        }

        &:last-child {
          border-bottom: none;
        }

        @media (max-width: $breakSm) {
          font-size: 0.9rem;
          grid-template-columns: 26% 34% 32% 8%;

          .expiration, .last-used-at, .created-at{
            display: none;
          }
        }

        .api-keys-table-icon {
          font-size: inherit;
          color: map-get($fontColors, mainPurple);
          margin-right: 8%;
        }

        .delete {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          padding: 0 10px;
          cursor: pointer;

          .delete-popup {
            position: absolute;
            display: none;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 118px;
            height: 51px;
            background: map-get($dashboardLight, settingsBg);
            box-shadow: $boxShadowSecondary;
            border-radius: 8px;
            margin-bottom: 19px;
            font-family: $regularFont;
            line-height: 17px;
            color: map-get($fontColors, secondaryRed);
            left: 50%;
            transform: translate(-50%, 10px);
            z-index: 1000;
            top: 25px;

            &.delete-open {
              display: flex;
            }

            &:before {
              content: '';
              top: -7px;
              position: absolute;
              width: 18px;
              height: 18px;
              background: map-get($dashboardLight, settingsBg);
              transform: rotate(45deg);
              border-radius: 2px;
            }
          }

          .dots-icon {
            width: 4px;
            height: 19px;
            color: map-get($fontColors, mainGrey);

            svg {
              width: 4px;
              height: 19px;
            }
          }
        }

        .copy,
        .delete {
          .api-keys-table-icon {
            cursor: pointer;
          }

          .api-keys-table-icon-deleted {
            font-size: inherit;
            color: map-get($fontColors, errorColor);
          }

          .api-keys-table-icon-copied {
            color: map-get($btnBgColors, primaryGreen);
            font-size: $fontLarge;

            @media (max-width: $breakSm) {
              font-size: inherit;
            }
          }
        }

        .timer-container {
          display: flex;
          align-items: center;
          padding-left: 5px;

          @media (max-width: $breakSm) {
            justify-content: center;
          }

          .copy-container {
            display: flex;
            align-items: center;

            .icon-copied-container {
              padding-left: 50px;
              width: 100%;
            }

            .copy-box {
              display: block;
              margin-right: 10px;
              padding: 6px 31px;
              font-family: $boldFont;;
              font-size: 9px;
              line-height: 13px;
              letter-spacing: 0.1em;
              color: map-get($fontColors, mainBlack);
              background: map-get($btnBgColors, secondaryGrey);
              border-radius: 8px;

              @media (max-width: $breakSm) {
                padding: 6px 10px;
              }
            }

            .copy-visible {
              padding: 6px 16px;
              background: map-get($btnBgColors, primaryGreen);
              cursor: pointer;
            }

            .copy-icon-container {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              .copy-icon {
                width: 20px;
                height: 20px;
                color: map-get($fontColors, mainPurple);
              }
            }

            .info-icon {
              width: 20px;
              height: 20px;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .api-keys-btn {
    padding: 10px 20px;
    width: fit-content;
    color: map-get($fontColors, mainWhite);
    background-color: map-get($btnBgColors, primaryPurple);
    border-radius: $borderRadius12;
    margin-top: 15px;

    @media (max-width: $breakSm) {
      margin-top: 5px;
      font-size: $fontVerySmall;
      position: static;
      padding: 5px 12px 7px;
      border-radius: $borderRadius10;
    }

    &:hover {
      background-color: map-get($btnBgColors, primaryBlack);
    }

    .api-keys-btn-bolt {
      position: relative;
      height: 1.2rem;
      right: 5px;
      top: 3px;
    }
  }
}
