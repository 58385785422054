.my-account-page-content {
  background: map-get($bgColors, mainWhite);
  padding: 40px;
  margin: 3px 40px 3px 3px;
  box-shadow: $boxShadow;
  border-radius: $borderRadius12;

  @media (max-width: $breakSm) {
    width: 100%;
    padding: 20px;
    margin-top: 50px;
  }
}
