@import '../../styles/variables';

.flexHeader {
  display: flex;
  padding-right: 3vw;

  .exportButton {
    font-family: $boldFont;
    font-size: $fontVerySmall;
    letter-spacing: -0.08px;
    width: 300px;
    height: 35px;
    border-radius: 8px;
  }
}