.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;

  .no-data-text {
    font-family: $regularFont;
    font-size: $fontLarge;
    line-height: 26px;
    color: map-get($fontColors, mainGrey);
  }
}
