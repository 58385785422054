/* Border radius styles */
$borderRadius100: 100px;
$borderRadius36: 36px;
$borderRadius10: 10px;
$borderRadius12: 12px;
$borderRadius26: 26px;

// font sizes

$fontVerySmall: 12px;
$fontSizeRegular: 14px;
$fontMedium: 16px;
$fontSemiLarge: 17px;
$fontLarge: 18px;
$fontTitle: 19px;
$fontBilling: 20px;
$fontBannerSmall: 24px;
$fontBanner: 30px;
$fontHeading: 35px;

// breakpoints

$breakExtraLg: 1920px;
$breakLg: 1440px;
$breakMd: 1280px;
$breakSm: 850px;

/* Main fonts styles used in project */
@font-face {
  font-family: HelveticaRegular;
  src: local('HelveticaRegular'),
  url('../assets/fonts/HelveticaNowDisplayRegular/font.woff') format('woff');
}

@font-face {
  font-family: HelveticaMedium;
  src: local('HelveticaMedium'),
  url('../assets/fonts/HelveticaNowDisplayMd/font.woff') format('woff');
}

@font-face {
  font-family: HelveticaBold;
  src: local('HelveticaBold'),
  url('../assets/fonts/HelveticaNowDisplayBd/font.woff') format('woff');
}

@font-face {
  font-family: HelveticaXBold;
  src: local('HelveticaXBold'),
  url('../assets/fonts/HelveticaNowDisplayXBd/font.woff') format('woff');
}

//Use this way: font-family: $boldFont;
//font-weight: 400;
$regularFont: 'HelveticaRegular';
//font-weight: 500;
$mediumFont: 'HelveticaMedium';
//font-weight: 700;
$boldFont: 'HelveticaBold';
//font-weight: 800;
$xBoldFont: 'HelveticaXBold';

$boxShadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
$boxShadowSecondary: 0 10px 20px rgba(0, 0, 0, 0.15);
$boxShadowBig: 0 50px 56px rgba(0, 0, 0, 0.4);
$buttonHoverBoxShadow: 1px 5px 8px lightgrey;

/* Main font colors */
$fontColors: (
        mainBlack: #202020,
        mainGrey: #888888,
        lightGrey: #AFACC3,
        mainWhite: #FFFFFF,
        mainPurple: #7346f3,
        mainGreen: #6dff96,
        trnsprntWhite: rgba(255, 255, 255, 0.6),
        errorColor: #EF032D,
        secondaryRed: #FF4A6B,
        depositText: #202020,
        withdrawalText: #202020,
        successText: #07A721,
        midGrey: #C0C0C0,
);

/* Main button background colors */
$btnBgColors: (
        primaryBlack: #202020,
        primaryPurple: #7346f3,
        primaryGrey: #f5f5f5,
        primaryGreen: #6dff96,
        borderGrey: #a4a4a4,
        trnsprntWhite: rgba(255, 255, 255, 0.3),
        trnsprntPurpleBg: rgba(173, 171, 237, 0.3),
        secondaryGrey: #EDEDED,
        primaryRed: #FFE0E6,
);

//Background colors
$bgColors: (
        mainWhite: #FFFFFF,
        primaryWhite: #EAE4FD,
        secondaryWhite: #FAFAFA,
        secondaryGrey: #E9E9E9,
        primaryYellow: #FFD66D,
        secondaryPurple: #7B61FF,
        borderGrey: #D2D2D2,
        depositHighlight: #EDF3FF,
        withdrawalHighlight: #F3F1FF,
);

// Colors used in Billing page
$billingColors: (
        cardBorder: #e2e2e2,
        cardBackground: #f9f9f9
);

/* Colors used in Dashboard page */
$dashboardLight: (
        greyBg: #f7f7f7,
        lightPurpleGreyBg: rgba(244, 243, 255, 1),
        trnsprntPinkBg: rgba(237, 171, 234, 0.27),
        trnsprntPurpleBg: rgba(173, 171, 237, 0.27),
        trnsprntBorder: rgba(255, 255, 255, 0.4),
        trnsprntBorderBottom: rgba(216, 216, 216, 0.3),
        settingsBg: #2F2D3E
);

/* Dark mode colors */
$dashboardDark: (
        trnsprntPurpleDark: rgba(173, 171, 237, 0.1),
        trnsprntPinkBg: rgba(237, 171, 234, 0.27),
        trnsprntPurpleBg: rgba(173, 171, 237, 0.27),
        trnsprntBorder: rgba(255, 255, 255, 0.4),
        trnsprntBorderBottom: rgba(185, 185, 185, 0.1),
);

$legendColors: (
        infoBlue: #266FF8,
        alertRed: #EF032D,
        warningYellow: #F7C902,
        successGreen: #07E345,
        withdrawalDeposit: #EDE7FF,
);
