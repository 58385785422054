@import '../../styles/variables.scss';

.container {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: map-get($bgColors, mainWhite);
  min-width: 150px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 16px;
  color: map-get($fontColors, mainGrey);
  cursor: pointer;
  aspect-ratio: 2 / 1;
  padding: 8px;

  &:hover {
    color: map-get($fontColors, mainBlack);
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25));
    transform: scale(1.02);
  }

  .icon {
    width: 20px;
    height: 20px;

    @media screen and (min-width: $breakMd) {
      width: 40px;
      height: 40px;
    }
  }

  .text {
    font-family: $boldFont;
    font-size: $fontLarge;

    @media screen and (min-width: $breakMd) {
      font-size: $fontBilling;
    }
  }
}