@import '../../../styles/variables';

.container {
  display: flex;
  gap: 24px;
  align-items: center;
  text-wrap: nowrap;

  .header {
    color: map-get($fontColors, primaryBlack);
    font-family: $boldFont;
    font-size: $fontMedium;
  }

  .applyFiltersButton {
    height: 35px;
    max-width: 150px;
    font-size: $fontVerySmall;
    padding-inline: 6px;
  }

  .resetFilters {
    width: 40px;
    height: 20px;
    color: map-get($fontColors, mainGrey);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .active {
    color: map-get($legendColors, alertRed);
    cursor: pointer;
  }

  .datePicker {
    height: 35px;
    border-radius: 5px;
    padding: 8px;
    font-family: $regularFont;
  }

  .textInput {
    height: 35px;
    border-radius: 5px;
    padding: 8px;
    font-family: $regularFont;
  }
}