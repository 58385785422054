.risk-score {
  padding: 6px;
  gap: 6px;
  background: map-get($btnBgColors, primaryGreen);
  border-radius: 8px;
  font-family: $boldFont;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.1em;
  color: map-get($fontColors, mainBlack);
  margin-right: 8px;
  text-transform: uppercase;

  &.risk-medium {
    background: map-get($bgColors, primaryYellow)
  }

  &.risk-high {
    background: map-get($fontColors, errorColor);
    color: map-get($fontColors, mainWhite);
  }

  &.risk-owned {
    background: map-get($legendColors, infoBlue);
    color: map-get($fontColors, mainWhite);
  }

  &.risk-banned {
    background: map-get($legendColors, alertRed);
    color: map-get($fontColors, mainWhite);
  }

  &.capitalized {
    text-transform: capitalize;
  }
}

.text-risk-low {
  color: map-get($legendColors, successGreen) !important;
}

.text-risk-medium {
  color: map-get($legendColors, warningYellow) !important;
}

.text-risk-high {
  color: map-get($fontColors, errorColor) !important;
}
