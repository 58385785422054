.mobile-menu-icon {
  display: none;
  position: fixed;
  top: 8px;
  left: 10px;
  font-size: 25px;
  z-index: 5;
  height: fit-content;

  @media (max-width: $breakSm) {
    display: block;
  }
}

.hamburger {
  position: relative;
  display: block;
  padding: 8px;
  background-color: transparent;
  border: none;

  &.open {
    .hamburger-line:first-child {
      transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger-line:nth-child(2) {
      opacity: 0;
    }

    .hamburger-line:last-child {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }

  .hamburger-line {
    display: block;
    width: 24px;
    height: 2px;
    margin: 5px 0;
    background-color: #333;
    transition: transform 0.3s ease;
  }
}

