.small-device-notification-container {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 17px;
  height: 100%;
  padding: 0 20px 40px 20px;

  @media (max-width: $breakSm) {
    display: flex;
  }

  .notification-icon {
    padding: 18px;
    background: map-get($bgColors, primaryWhite);
    border-radius: 33px;
    margin-top: auto;
  }

  .notification-text-container {
    margin: 0 40px;

    .screen-too-small {
      font-family: $boldFont;
      font-size: $fontLarge;
      line-height: 26px;
      letter-spacing: -0.01em;
      color: map-get($fontColors, mainBlack);
    }

    .screen-too-small-description {
      margin-top: 4px;
      font-family: $mediumFont;
      font-size: $fontMedium;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.01em;
      color: map-get($fontColors, mainGrey);
    }
  }

  .main-button {
    margin-top: auto;
  }
}