/* NFT Collection component styles */

.nft-collection-btn-container {
  font-family: $regularFont;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  background: linear-gradient(rgb(77, 39, 164), #9198e5) no-repeat center;
  background-size: cover;

 @media (max-width: $breakSm) {
    display: none;
  }

  .nft-collection-btn {
    background-color: map-get($btnBgColors, trnsprntPurpleBg);
    backdrop-filter: blur(100px);
    width: fit-content;
    color: map-get($fontColors, mainWhite);
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    border: 1px solid map-get($btnBgColors, trnsprntWhite);
    border-radius: $borderRadius26;
    position: absolute;
    bottom: 0;
    left: 25%;
    display: none;

    .nft-collection-icon {
      font-size: $fontBanner;
      margin-right: 5px;
    }

    .nft-collection-title {
      margin: 0;
      padding: 0;
      font-size: $fontBilling;
    }

    .nft-collection-link {
      text-decoration: none;
      color: map-get($fontColors, trnsprntWhite);

      .nft-collection-link-icon {
        position: relative;
        right: 5px;
        top: 3px;
        font-size: 1rem;
        left: 2px;
      }
    }
  }
}
