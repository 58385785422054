.users-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;

  @media (max-width: $breakSm) {
    display: none;
  }

  .users-main-container {
    background: map-get($bgColors, mainWhite);
    padding: 28px 40px;
    box-shadow: $boxShadow;
    border-radius: $borderRadius12;
    flex: 1;

    .users-table-header {
      font-family: $boldFont;;
      font-size: $fontVerySmall;
      line-height: 18px;
      letter-spacing: 0.14em;
      color: map-get($fontColors, mainBlack);

      span {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .sort-icon-container {
        width: 20px;
        transform: rotate(90deg);
        cursor: pointer;
      }

      .sort-icon-inactive {
        color: map-get($fontColors, lightGrey);
        transform: rotate(90deg);
      }

      .sort-icon-active {
        color: map-get($fontColors, mainPurple);
      }

      .sort-icon-asc {
        transform: rotate(90deg);
      }

      .sort-icon-desc {
          transform: rotate(270deg);
      }

      .info-icon {
        width: 20px;
        height: 20px;
      }
    }

    .users-table-row {
      display: grid;
      grid-template-columns: 16% repeat(6, 1fr);
      padding-top: 29px;
      padding-bottom: 27px;
      border-bottom: 1.5px solid #E9E9E9;
      align-items: center;

      &:first-child, &:last-child {
        border: none;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .users-table-name {
        font-family: $regularFont;
        font-size: $fontMedium;
        line-height: 24px;
        text-decoration-line: underline;
        color: map-get($fontColors, mainPurple);
        cursor: pointer;
        transition: color .4s;

        &:hover {
          color: map-get($fontColors, mainBlack);
        }
      }

      .users-table-providers-container {
        display: flex;
        gap: 5px;

        .provider {
          position: relative;

          .provider-icon {
            width: 28px;
            height: 28px;
            cursor: pointer;

            .provider-img {
              width: 100%;
              height: 100%;
            }

            .provider-tooltip {
              position: absolute;
              display: flex;
              align-items: center;
              flex-direction: column;
              background-color: #2F2D3E;
              color: white;
              top: 38px;
              width: 350px;
              height: 52px;
              padding: 10px 18px;
              border-radius: 8px;
              transform: translateX(calc(-50% + 10px));
              z-index: 2;
              text-align: center;

              &:before {
                content: "";
                top: 0;
                position: absolute;
                width: 18px;
                height: 18px;
                background-color: #2F2D3E;
                transform: rotate(45deg) translateX(-50%);
                border-radius: 2px;
                left: 50%;
              }

              .addresses-count {
                font-size: $fontSizeRegular;
                color: map-get($fontColors, mainWhite);
              }

              .copy-to-clipboard {
                font-size: 10px;
                text-transform: uppercase;
                color: map-get($fontColors, lightGrey);
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }

      .users-table-font-secondary {
        font-family: $regularFont;
        font-size: $fontMedium;
        line-height: 24px;
        color: map-get($fontColors, mainGrey);
      }

      .users-table-risk-score {
        gap: 8px;
      }

      .users-socials {
        display: flex;
        gap: 5px;
      }
    }

    .no-data-center {
      text-align: center;
    }
  }

  .user-list-subheader-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .user-list-subheader-item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: $boldFont;;
      font-size: $fontMedium;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: map-get($fontColors, mainPurple);
      cursor: pointer;
      border-bottom: 1px solid #7346F3;
      padding: 0 4px;

      .icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .search-input {
        border: none;
        width: 100%;

        &::placeholder {
          font-family: $boldFont;;
          font-size: $fontMedium;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: map-get($fontColors, mainPurple);
        }

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

