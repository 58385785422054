.my-projects-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
  gap: 23px;
  padding: 10px;

  @media(max-width: $breakMd) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-width: $breakSm) {
    grid-template-columns: repeat(2, 1fr);
  }
}
