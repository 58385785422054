//new layout
.dashboard-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding-right: 40px;

  @media (max-width: $breakSm) {
    padding: 4px;
    margin-top: 60px;
  }
}

.dashboard-section {
  flex: 1;

  .dashboard-section-list-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 37px;
    margin-bottom: 15px;

    @media (max-width: $breakSm) {
      margin: 20px 0;
    }

    .see-all-button {
      font-family: $mediumFont;
      font-size: $fontMedium;
      line-height: 19px;
      letter-spacing: -0.01em;
      text-decoration-line: underline;
      color: map-get($dashboardLight, settingsBg);
    }

    .dashboard-section-title {
      font-family: $boldFont;
      font-size: 21px;
      line-height: 31px;
      color: map-get($dashboardLight, settingsBg);
    }
  }
}

.dashboard-cards {
  display: flex;
  width: 100%;
  overflow-x: auto;
  gap: 20px;
  padding: 8px;
  height: calc(50vh - 100px);
  float: left;
  flex-wrap: wrap;

  @media (max-width: $breakSm) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.module-cards {
  display: grid;
  width: 100%;
  height: auto;
  overflow-x: auto;
  gap: 20px;
  padding: 8px;
  float: left;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.my-projects-card {
  aspect-ratio: .8;
  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: $breakSm) {
    flex: 0 0 60%;
  }
}