.sidebar-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 16px 13px;
  border-radius: 12px;
  border: 2px solid rgba(239, 3, 45, 0.20);
  background: map-get($btnBgColors, primaryRed);

  .alert-icon {
    width: 32px;
    height: 32px;

    path {
      stroke: map-get($fontColors, errorColor);
    }
  }

  .sidebar-alert-text {
    text-align: center;
    color: map-get($fontColors, errorColor);

    .title {
      font-size: $fontSizeRegular;
      font-family: $boldFont;
      letter-spacing: -0.14px;
    }

    .text {
      font-size: $fontSizeRegular;
      font-family: $mediumFont;
      letter-spacing: -0.14px;
    }
  }

  .main-button {
    font-size: $fontVerySmall;
    padding: 4px 10px 6px 10px;
    background-color: map-get($fontColors, errorColor);
    color: map-get($fontColors, mainWhite);
    letter-spacing: 0.48px;
    font-family: $xBoldFont;
  }
}

.collapsed {
  .sidebar-alert {
    background-color: unset;
    border: none;
  }
}