@import '../../styles/variables';

.explorerForm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .addressInputOuter {
    position: relative;
    display: inline-block;
    margin: 0;
    border: 0;
    padding: 0;
    border-radius: 0;
    width: calc(100% - 360px);
    height: 55px;
  }

  .addressInputInner {
    margin: 0;
    border: 1.5px solid map-get($bgColors, borderGrey);
    padding: 13px 0 13px 31px;
    border-radius: 10px;
    width: 100%;
    height: 55px;
  }

  .connectButton {
    width: 320px;
    height: 55px;
    margin-top: 0;
  }
}

.autocompleteItems {
  position: absolute;
  border: 1px solid map-get($bgColors, borderGrey);
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;

  div {
    padding: 10px;
    cursor: pointer;
    background-color: map-get($bgColors, mainWhite);
    border-bottom: 1px solid map-get($bgColors, borderGrey);

    &:hover {
      background-color: map-get($bgColors, secondaryGrey);
    }
  }
}
