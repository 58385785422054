.container {
  flex: 1;
  padding-right: 40px;

  .moduleCards {
    display: grid;
    width: 100%;
    height: auto;
    overflow-x: auto;
    gap: 20px;
    padding: 8px;
    float: left;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}