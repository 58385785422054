
.my-account-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  min-width: 300px;

  .divider {
    margin: 28px 0;
    height: 1.5px;
    background: map-get($bgColors, secondaryGrey);
    width: 100%;
  }

  .name-form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 25%;
    min-width: 300px;
  }

  .password-form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 25%;
    min-width: 300px;

    .main-form {
      gap: 16px;

      .main-form-input {
        margin-top: 0;
      }
    }
  }

  .main-form-input {
    padding: 15px 0 15px 20px;
    border: 1px solid map-get($btnBgColors, borderGrey);
    border-radius: $borderRadius10;
    font-size: 1rem;
  }

  .delete-account-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .button-error {
      width: 25%;
      min-width: 300px;
    }
  }

  .language-settings-container {

  }

  .form-description {
    font-family: $regularFont;
    font-size: $fontMedium;
    line-height: 24px;
    color: map-get($fontColors, mainGrey);
  }

  .form-title {
    font-family: $boldFont;;
    font-size: $fontVerySmall;
    line-height: 18px;
    letter-spacing: 0.14em;
    color: map-get($fontColors, mainBlack);
  }

  @media (max-height: 800px) {
    .divider {
      margin: 14px 0;
    }
  }
}

.input-alert {
  color: map-get($fontColors, errorColor);
  margin-right: auto;
}