.page-content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .main-button {
    padding: 18px 55px;
    margin: 0 12px;
    width: auto;
  }

  .loader {
    width: 405px;
    position: unset;
    transform: unset;
    margin-left: 24px;
  }
}

.analytics-title {
  font-size: $fontBannerSmall;
  font-family: $boldFont;
  line-height: 35px;
  margin: 8px 0;
}

.analytics-details {
  font-size: $fontLarge;
  font-family: $regularFont;
  line-height: 140%;
  text-align: center;
  padding: 0 70px;
  letter-spacing: 0.02em;
  color: map-get($fontColors, mainGrey);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 690px;
  align-items: center;
  margin-bottom: 30px;

  .analytics-icon-container {
    width: 20px;
    height: 20px;
    color: map-get($fontColors, mainPurple);
  }

  @media (max-width: $breakSm) {
    width: unset;
  }
}

.upload-text {
  font-family: $regularFont;
  font-size: $fontMedium;
  line-height: 140%;
  color: map-get($fontColors, mainGrey);
}

.import-btn {
  border-radius: 12px;
  width: 300px;
  font-size: $fontVerySmall;
  height: 54px;
  letter-spacing: 0.1em;
  padding: 18px 91px;
  color: map-get($fontColors, mainWhite);
  background-color: map-get($btnBgColors, primaryPurple);
  margin: 16px 0 30px;
}

.icon-container {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  background: map-get($bgColors, primaryWhite);
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    stroke: map-get($btnBgColors, primaryPurple);
  }
}

.analytics-loader {
  margin-top: 42px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 42px auto;

  .button-purple-light {
    width: 508px;

    @media (max-width: $breakSm) {
      width: 300px;
      padding: 18px 10px;
    }
  }

  .button-purple {
    @media (max-width: $breakSm) {
      width: 300px;
      padding: 18px 10px;
    }
  }

  @media (max-width: $breakSm) {
    flex-direction: column;
    gap: 20px;
  }
}

.analytics-link {
  display: flex;
  font-size: $fontMedium;
  color: map-get($fontColors, mainPurple);

  @media (max-width: $breakSm) {
    font-size: $fontVerySmall;
  }

  .icon {
    width: 21px;
    height: 20px;
  }
}

.analytics-link-text {
  margin-left: 10px;
  text-decoration: underline;
}