.analytics-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 3px;
}

.analytics-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media(max-width: $breakLg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: $breakSm) {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }

  .top-section-icon {
    color: map-get($fontColors, mainPurple);
  }
}

.analytics-box, .chart-wrapper {
  .settings-modal {
    padding: 16px 19px 19px;
    transform: translateX(-20%);
    width: 132px;
  }
}

.analytics-box {
  background: map-get($bgColors, mainWhite);
  box-shadow: $boxShadow;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: $borderRadius12;

  .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-wrapper {
      svg {
        width: 20px;
        height: 20px;
        path {
            stroke: map-get($btnBgColors, primaryPurple);
          }
        }
      }
    }

  .settings-icon {
    width: 89px;
  }

  .tooltip {
    &::before {
      width: 106px;
      height: 17px;
      min-height: unset;
    }
  }

  .detail-dash-icon {
    border: solid 1px map-get($fontColors, mainGrey);
    border-radius: 50%;
    display: flex;
    padding: 4px;
    cursor: pointer;
    transform: rotate(180deg);
    height: 20px;
    width: 20px;

    path {
      stroke: map-get($fontColors, mainGrey);
    }

    &:hover {
      background-color: map-get($btnBgColors, primaryPurple);
      border-color: map-get($btnBgColors, primaryPurple);
      padding: 5px;
      height: 24px;
      width: 24px;

      path {
        stroke: map-get($fontColors, mainWhite);
      }
    }
  }

  .settings-wrapper {
    position: relative;
  }

  .settings-icon-wrapper {
    cursor: pointer;
    padding: 5px;
    position: relative;
  }

  .icon-wrapper {
    width: 44px;
    height: 44px;
    background: rgba(234, 228, 253, 1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-number {
    font-size: 40px;
    font-family: $boldFont;
  }

  .box-footer {
    display: flex;
    justify-content: space-between;
    color: map-get($fontColors, mainGrey);
    align-items: center;
  }

  .box-description {
    font-size: $fontLarge;

    @media (max-width: $breakSm) {
      font-size: $fontSizeRegular;
    }
  }
}

.box-options {
  position: relative;
}

.box-time-range-text {
  font-size: $fontSizeRegular;
  line-height: 21px;
  font-family: $mediumFont;
  color: map-get($fontColors, mainGrey);
  margin-right: 6px;
}

.box-time-range {
  display: flex;
  align-items: center;
  padding: 3px 7px 4px 12px;
  cursor: pointer;

  &:hover {
    background-color: map-get($btnBgColors, primaryGrey);
  }

  &:hover, &.active {
    background-color: map-get($btnBgColors, primaryGrey);
    border-radius: 50px;
  }

  svg {
    transform: rotate(-90deg);
    height: 14px;
    width: 14px;

    path {
      stroke: map-get($fontColors, mainGrey);
    }
  }
}

.analytics-charts-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(535px, 1fr));
  gap: 40px;

  @media (min-width: $breakExtraLg) {
    grid-template-columns: repeat(auto-fit, minmax(750px, 1fr));
  }

  @media (max-width: $breakSm) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    margin-bottom: 70px;

    .chart-wrapper {
      padding: 10px 15px;
    }
  }

  .top-tokens-chart {
    grid-column: 1 / -1;
  }
}

.analytics-banner-container {
  display: flex;
  height: 93px;
  padding: 33px 3%;
  border-radius: $borderRadius12;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $breakMd) {
    padding: 20px 30px;
  }

  @media (max-width: $breakMd) {
    display: none;
  }
}

.star-tag {
  padding: 7px 17px 8px;
  height: 33px;
}

.analytics-banner-label {
  font-family: $boldFont;
  font-size: $fontVerySmall;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: map-get($fontColors, mainWhite);
}

.analytics-banner-main-text {
  font-family: $xBoldFont;
  font-size: $fontBanner;
  line-height: 106%;
  letter-spacing: -0.01em;
  color: map-get($fontColors, mainWhite);
  @media (max-width: $breakMd) {
    font-size: $fontBannerSmall;
  }
}

.analytics-banner-link {
  color: map-get($fontColors, mainWhite);
  border-bottom: 2px solid map-get($fontColors, mainWhite);
  width: fit-content;
  font-size: $fontLarge;
  font-family: $xBoldFont;
}

.widget-details {
  margin-top: 22px;
  display: flex;
  .select-with-checkbox-group {
    width: auto;
    margin-bottom: 16px;
  }
}
.widget-details-column {
  width: 80%;
  margin-right: 37px;
}

.widget-details-box {
  height: 352px;
  font-size: $fontBanner;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.select {
  margin-bottom: 16px;
}

.select-dropdown, .options-wrapper {
  z-index: 100;
}

.api-usage-filter-header {
  font-size: $fontMedium;
  font-family: $boldFont;
  margin-bottom: 16px;
}