.vertical-bar-chart-wrapper {
  height: 200px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: $fontVerySmall;

  .bar-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;

    .bar-label {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      width: 25%;
      font-family: $boldFont;
      color: map-get($fontColors, mainBlack);
      white-space: nowrap;

      @media (max-width: $breakSm) {
        width: 30%;
      }

      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .country-icon {
        margin-left: 15px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .copy-to-clipboard-tooltip {
      top: unset;
      left: 90%;
      transform: unset;
      &:after {
        top: 50%;
        left: 0;
      }
    }

    .copy-to-clipboard {
      display: flex;
      flex-direction: column;
    }

    .bar-line-wrapper {
      width: 60%;
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: $breakSm) {
        width: 50%;
      }
    }

    .bar-line {
      position: relative;
      height: 6px;
      display: flex;
      align-items: center;
      border-radius: 8px;
    }

    .bar-number {
      position: absolute;
      left: 100%;
      margin-left: 5px;
      white-space: nowrap;
    }
  }
}