.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  font-family: $mediumFont;
  letter-spacing: 0.14em;
  border: none;
  border-radius: $borderRadius12;
  padding: 16px 0;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color .5s ease-in-out;

  @media (max-width: $breakSm) {
    font-size: $fontSizeRegular;
  }

  &.button-error {
    color: map-get($fontColors, errorColor);
    background: map-get($btnBgColors, primaryRed);

    &:hover {
      color: map-get($fontColors, mainWhite);
      background: map-get($fontColors, errorColor);

      svg {
        path {
          stroke: map-get($fontColors, mainWhite);
        }
      }
    }
  }

  &.button-error {
    color: map-get($fontColors, errorColor);
    background: map-get($btnBgColors, primaryRed);

    &:hover {
      color: map-get($fontColors, mainWhite);
      background: map-get($fontColors, errorColor);

      svg {
        path {
          stroke: map-get($fontColors, mainWhite);
        }
      }
    }
  }

  &.button-black {
    background: map-get($btnBgColors, primaryBlack);
    color: map-get($fontColors, mainWhite);

    &:hover {
      background: map-get($btnBgColors, primaryPurple);
    }
  }

  &.button-purple {
    background: map-get($btnBgColors, primaryPurple);
    color: map-get($fontColors, mainWhite);

    &:hover {
      background: map-get($btnBgColors, primaryBlack);
    }
  }

  &.button-purple-light {
    background: map-get($bgColors, primaryWhite);
    color: map-get($fontColors, mainPurple);

    svg {
      path {
        stroke: map-get($fontColors, mainPurple);
      }
    }
    &:hover {
      background: map-get($btnBgColors, primaryPurple);
      color: map-get($fontColors, mainWhite);

      svg {
        path {
          stroke: map-get($fontColors, mainWhite);
        }
      }
    }
  }

  &.button-gray {
    background: map-get($btnBgColors, primaryGrey);
    color: map-get($fontColors, mainBlack);

    &:hover {
      background: map-get($btnBgColors, primaryPurple);
      color: map-get($fontColors, mainWhite);

      svg {
        path {
          stroke: map-get($fontColors, mainWhite);
        }
      }
    }
  }
  &.button-green {
    background-color: map-get($btnBgColors, primaryGreen);
    color: map-get($fontColors, mainBlack);
    font-size: $fontVerySmall;
    height: 33px;
    gap: 4px;
    margin-top: 0;
    border-radius: 8px;
  }

  &:disabled {
    background: map-get($bgColors, borderGrey);
    color: map-get($fontColors, mainGrey);
    cursor: not-allowed;

    &:hover {
      background: map-get($bgColors, borderGrey);
      color: map-get($fontColors, mainGrey);
    }
  }

  &.button-no-data {
    max-width: 250px;
    background: map-get($bgColors, primaryWhite);
    color: map-get($fontColors, mainPurple);

    svg {
      path {
        stroke: map-get($fontColors, mainPurple);
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
}

/* Google / Github button styles */
.sign-in-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
}

.google-github-btn {
  background-color: map-get($btnBgColors, primaryGrey);
  border: 1px solid map-get($btnBgColors, primaryGrey);
  padding: 10px 0;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.14em;

  .google-github-icon {
    position: relative;
    font-size: 1.2rem;
    margin-right: 10px;
    padding: 0;
  }
}

.link-button {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background: transparent;
  font-family: $regularFont;
  font-size: $fontMedium;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  text-underline-offset: 4px;
  color: map-get($fontColors, mainPurple);

  &:disabled {
    color: map-get($fontColors, mainGrey);
    cursor: not-allowed;

    svg {
      path {
        stroke: map-get($fontColors, mainGrey);
      }
    }
  }

  .link-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
