@import '../../../styles/variables';

.container {
  position: relative;

  .textWithIcon {
    display: flex;
    align-items: center;
    gap: 6px;
    color: map-get($fontColors, mainGrey);
    cursor: pointer;

    .text {
      font-family: $boldFont;
      font-size: $fontMedium;
      letter-spacing: -0.16px;
    }

    .icon {
      width: 20px;
      height: 20px;

      svg {
        transform: rotate(-90deg);
        width: 20px;
        height: 20px;
      }
    }
  }

  .active {
    color: map-get($fontColors, mainPurple);
    text-decoration-line: underline;
  }

  .priceSettingsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    top: 100%;
    width: 200px;
    border-radius: 8px;
    padding: 20px;
    background: map-get($bgColors, mainWhite);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
    z-index: 10;

    .inputWithCurrency {
      display: flex;
      align-items: center;
      justify-content: center;

      .rangeInput {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        padding: 4px 24px 4px 8px;
      }

      .currencySymbol {
        width: 0;
        height: 20px;
        position: relative;
        left: -20px;
      }
    }

    .setRangeButton {
      font-size: $fontVerySmall;
      width: 100%;
      height: 35px;
    }
  }
}