.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  position: relative;
  gap: 32px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 315px;
  height: 25px;

  .pagination-page-container {
    display: flex;
    align-items: center;
    gap: 14px;

    .pagination-text {
      font-family: $boldFont;
      font-size: $fontMedium;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: map-get($fontColors, mainGrey);
    }

    .dash-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: $borderRadius12;
      border: none;
      background: map-get($bgColors, primaryWhite);
      cursor: not-allowed;

      svg {
        width: 11px;
        height: 11px;

        path {
          stroke: #7346F3;
        }
      }

      .dash-right {
        transform: rotate(180deg);
      }
    }

    &.pagination-active {
      .pagination-text {
        color: map-get($fontColors, mainPurple);
      }

      .dash-button-container {
        background: map-get($btnBgColors, primaryPurple);
        cursor: pointer;

        svg {
          path {
            stroke: map-get($bgColors, mainWhite);
          }
        }
      }
    }
  }

  .pagination-divider {
    width: 1.5px;
    background: map-get($bgColors, secondaryGrey);
  }
}
