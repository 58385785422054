@import '../../styles/variables';

.containerBase {
  display: grid;
  color: map-get($fontColors, mainGrey);
  font-size: $fontMedium;
  font-family: $mediumFont;
  text-transform: capitalize;

  .externalLinkContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    color: map-get($fontColors, mainPurple);

    .icon {
      width: 20px;
      height: 20px;
    }

    .link {
      color: map-get($fontColors, mainPurple);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.container {
  grid-template-columns: repeat(4, 1fr);
}

.containerExpandable {
  grid-template-columns: 40px repeat(4, 1fr) 20px;
}

.lowOpacity {
  opacity: 0.4;
}

.centeredHorizontally {
  align-items: center;

  .infoIconContainer {
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
