.modal-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 46px;

  .modal-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: map-get($bgColors, primaryWhite);
    width: 44px;
    height: 44px;
  }

  .users-modal-title {
    display: flex;
    align-items: center;
    font-family: $boldFont;;
    font-size: $fontBannerSmall;
    line-height: 35px;
    color: map-get($dashboardLight, settingsBg);
  }
}

.import-section-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .divider {
    position: relative;
    width: 1.5px;
    background: map-get($bgColors, secondaryGrey);
    margin: 0 40px;

    &:before {
      content: 'OR';
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      position: absolute;
      width: 18px;
      height: 36px;
      font-family: $boldFont;
      font-size: $fontVerySmall;
      line-height: 18px;
      letter-spacing: 0.14em;
      background: map-get($bgColors, mainWhite);
      color: map-get($fontColors, mainBlack);
      transform: translate(-50%, -50%);
    }
  }

  .import-by-contract-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .select {
      margin-bottom: 0;
    }
  }

  .import-by-csv-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .import-method-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: $boldFont;
    font-size: $fontMedium;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: map-get($dashboardLight, settingsBg);

    .tooltip {
      display: flex;
      align-items: center;
    }
  }
}

.users-list-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
  padding: 5px 20px;
  gap: 8px;
  border: 2px solid map-get($bgColors, borderGrey);
  border-radius: $borderRadius10;
  margin-bottom: 16px;

  .select-placeholder {
    font-family: $regularFont;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: map-get($fontColors, mainGrey);
  }
}

.users-list-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
  padding: 5px 20px;
  gap: 8px;
  border: 2px solid map-get($bgColors, borderGrey);
  border-radius: $borderRadius10;
  margin-bottom: 16px;

  &:focus {
    outline: none;
    border: 2px solid map-get($fontColors, mainPurple);
  }

  &::placeholder {
    font-family: $mediumFont;
    font-size: $fontSizeRegular;
    line-height: 140%;
    letter-spacing: 0.01em;
  }

  &:disabled {
    background: #EFEFEF4D;
    cursor: not-allowed;
  }
}

.export-csv {
  display: flex;
  gap: 10px;
  cursor: pointer;

  .icon {
    color: map-get($fontColors, mainPurple);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .export-csv-text {
    font-family: $regularFont;
    font-size: $fontMedium;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    color: map-get($fontColors, mainPurple);
  }

  .file-icon {
    width: 20px;
    height: 20px;
    color: map-get($fontColors, mainPurple);
  }
}

.export-buttons-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 15px
}

//file input
.file-input {
  position: relative;
}

.file-input-drop-zone {
  border: 2px dashed map-get($bgColors, borderGrey);
  border-radius: $borderRadius10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 124px;

  &.dropzone-disabled {
    background: #EFEFEF4D;
    cursor: not-allowed;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.file-input-label {
  color: map-get($fontColors, mainGrey);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}

.file-input-link {
  font-weight: $boldFont;
  color: map-get($fontColors, mainPurple);
}

.file-input-input {
  display: none;
}

.file-input-error {
  height: 32px;
  padding: 4px;
  text-align: center;
  color: map-get($fontColors, errorColor);
}

.file-input-filename {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-input-delete-icon {
  font-size: $fontBannerSmall;
  cursor: pointer;
  position: absolute;
  right: 16px;
}
