.select {
  position: relative;

  .select-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    gap: 8px;
    height: 54px;
    border: 2px solid map-get($bgColors, borderGrey);
    border-radius: 10px;
    cursor: pointer;

    .dash-icon {
      width: 20px;
      height: 20px;
    }

    &.selected {
      border: 2px solid #7346F3;
    }

    .dash-icon {
      transform: rotate(-90deg);

      &.dash-icon-reverse {
        transform: rotate(90deg);
      }

      path {
        stroke: map-get($btnBgColors, primaryBlack);
      }
    }

    .select-input {
      width: 100%;
      height: 100%;
      font-family: $mediumFont;
      font-size: $fontSizeRegular;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: map-get($btnBgColors, primaryBlack);
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    &.select-disabled {
      background: #EFEFEF4D;
    }
  }

  .select-dropdown {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    border: 2px solid map-get($bgColors, borderGrey);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: map-get($bgColors, secondaryWhite);
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    gap: 10px;
    z-index: 10;

      .dropdown-item {
        display: flex;
        gap: 12px;
        font-family: $mediumFont;
        font-size: $fontSizeRegular;
        line-height: 140%;
        letter-spacing: 0.01em;
        cursor: pointer;

        &:hover {
          background: #dfecff;
        }
      }
    }
  }
