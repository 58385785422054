.project-settings-container {
  width: 100%;
  padding: 28px 10px 28px 40px;
  background: map-get($bgColors, mainWhite);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius12;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: $breakSm) {
    padding: 28px 10px;
  }

  .project-settings-title {
    font-family: $boldFont;
    font-size: $fontVerySmall;
    line-height: 18px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
  }

  .single-setting-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 25%;
    gap: 16px;

    .project-name {
      text-transform: capitalize;
      font-family: $mediumFont;
      font-size: $fontMedium;
      line-height: 24px;
      color: map-get($fontColors, mainGrey);
    }

    .button-purple {
      margin-top: 24px;
    }

    .main-form-input {
      margin-bottom: 6px;
    }
  }

  .divider {
    margin: 38px 0;
    width: 80%;
    border: 1.5px solid #E9E9E9;
  }

  .delete-project-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .button-error {
      min-width: 300px;
      width: 25%;
      margin-top: 24px;
    }

    .project-delete-info {
      font-family: $mediumFont;
      font-size: $fontMedium;
      line-height: 140%;
      letter-spacing: 0.02em;
      color: map-get($fontColors, mainGrey);
    }
  }
}
