.copy-to-clipboard-tooltip-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

  &.active {
    text-decoration: underline;
  }

  svg {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .copy-to-clipboard-tooltip {
    position: absolute;
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 10px;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    min-width: 128px;
    min-height: 38px;
    text-align: center;
    z-index: 1000;
    font-family: $regularFont;
    font-size: $fontVerySmall;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: #333;
      transform: rotate(45deg) translateX(-50%);
    }
    .value {
      font-size: $fontSizeRegular;
    }

    .tooltip-action {
      font-size: 10px;
      text-transform: uppercase;
      font-family: $mediumFont;
      color: map-get($fontColors, lightGrey);
    }
  }
}
