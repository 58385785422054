.delete-project-modal-info-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;

  .trash-icon-container {
    display: flex;
    align-items: center;
    background: map-get($btnBgColors, primaryRed);
    border-radius: 22px;
    padding: 12px;
  }
}

.delete-project-modal-actions-section {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 300px;

  .delete-modal-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px 5px 20px;
    gap: 8px;
    width: 298px;
    height: 54px;
    border: 2px solid map-get($bgColors, borderGrey);
    border-radius: 10px;

    &::placeholder {
      line-height: 140%;
      letter-spacing: 0.01em;
      color: map-get($fontColors, mainGrey);
    }
  }

  .delete-button {
    margin-top: 16px;
  }
}
