
/* Footer-Cookies styles */
.main-footer {
  font-family: $regularFont;
  color: map-get($fontColors, mainGrey);
  text-align: start;

  .main-footer-text-link {
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    &:hover {
      color: map-get($fontColors, mainBlack);
    }
  }

  @media (max-width: $breakSm) {
    margin-top: 4px;
    font-size: $fontVerySmall;
  }
}
