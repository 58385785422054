/* Dashboard page styles */
.main-container {
  font-family: $regularFont;
  color: map-get($fontColors, mainBlack);
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 3%;
  box-sizing: border-box;
  width: 100%;

  .form-wrapper {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    &.sign-up {
      display: flex;
      flex-direction: column;
      gap: 26px;
      max-width: 460px;

      .heading-title-text {
        margin: 14px 0 0;
      }

      @media (max-width: $breakSm) {
        gap: 14px;

        .heading-title {
          font-size: 28px;
        }

        .heading-title-text {
          margin-top: 10px;
          font-size: $fontSizeRegular;
        }
      }

      @media (max-width: $breakMd) {
        gap: 20px;

        .heading-title {
          font-size: 32px;
        }
      }

      .form-error-message {
        font-size: $fontSizeRegular;
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: $breakSm){
    padding: 6%;
  }
  .dashboard-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

   @media (max-width: $breakSm) {
      margin-bottom: 5%;
    }
  }
}
.banner-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: map-get($btnBgColors, primaryGreen);
  border-radius: 8px;
  right: 30px;
  top: 30px;
  text-transform: uppercase;
  font-family: $boldFont;

    @media (max-width: $breakSm) {
      right: 5px;
      top: 2px;
      font-size: 10px;
    }
}

.time-tag {
  padding: 7px 14px 8px;
  position: absolute;
  letter-spacing: 0.1em;

    @media (max-width: $breakSm) {
      padding: 7px;
  }
}

.banner-time-icon {
  margin-right: 7px;
}